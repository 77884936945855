/* eslint-disable no-loop-func */
import {
  getIn,
  setIn,
  is,
  toCamelCase,
  deleteIn,
  makeRowDataUnique,
  emptyList
} from 'utils'
import { Map, fromJS, Set, List } from 'immutable'
import editableGridBehaviors from 'components/EditableGrid/reducer'
import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import activityBehaviors from 'components/MasterScreen/Activities/reducer'
import auditBehaviors from 'components/MasterScreen/Audit/reducer'
import attachmentsBehaviors from 'components/MasterScreen/Attachments/reducer'
import notesModalBehaviors from 'modals/NotesModal/reducer'
import productImportBehaviors from 'components/ProductImport/behaviors'
import { handleSalesmanCommissionData } from 'pages/InvoiceInquiry/utils'
import {
  CHANGE_COMMISSION_PROPERTY,
  CHANGE_COMMISSION_VALUE
} from 'pages/InvoiceInquiry/constants'

import * as DDICONSTANTS from 'ddiForm/constants'
import * as DDIMASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import * as CONSTANTS from './constants'
import {
  removeSuppressedReference,
  handleCopyOrderData,
  handleFastProductData,
  updateGroups,
  resetPaymentModalFlags,
  emptyRow,
  handleSecondaryTabData
} from './utils'

const primaryTabs = [
  'header',
  'detail',
  'final',
  'shipments',
  'audit',
  'activities'
]

const clearReasonCode = state => {
  let result = state
  result = deleteIn(result, `values.reasonCode`)
  result = deleteIn(result, `fields.reasonCode`)
  return result
}
const clearFieldsAndValues = state => {
  let result = state
  let fields = getIn(result, 'fields')
  fields = fields.map((value, key) => {
    if (getIn(value, 'grid')) {
      value = setIn(value, 'rowData', fromJS([]))
    } else {
      let temp = getIn(value, 'value')
      if (typeof temp === 'number' || typeof temp === 'string') {
        temp = ''
      }
      if (typeof temp === 'boolean') {
        temp = false
      }
      if (typeof temp === 'object' && temp != null) {
        if (Array.isArray(temp)) {
          temp = fromJS([])
        } else {
          temp = fromJS({})
        }
        // temp =  fromJS({})
      }
      value = setIn(value, 'value', temp)
      value = setIn(value, 'prevValue', undefined)
      value = setIn(value, 'errorMessage', '')
      value = setIn(value, 'description', '')
      if (getIn(value, 'isInvalid')) {
        value = setIn(value, 'isInvalid', false)
      }
    }
    return value
  })
  let values = getIn(result, 'values')
  values = values.map((value, key) => {
    if (typeof value === 'number' || typeof value === 'string') {
      return ''
    }
    if (typeof value === 'boolean') {
      return false
    }
    if (typeof value === 'object' && value != null) {
      if (Array.isArray(value)) {
        return []
      }
      return {}
    }
    return null
  })
  result = setIn(result, 'values', values)
  result = setIn(result, 'fields', fields)

  result = setIn(result, 'fields.lineItems.isPending', false)
  result = setIn(result, 'fields.miscellaneousCharges.isPending', false)

  return result
}

const clearAdditionalData = state =>
  setIn(state, 'additionalDataMap', fromJS({}))

const clearSalesOrder = state => {
  let result = state
  result = setIn(result, 'hasRecord', false)
  result = setIn(result, 'isEditing', false)
  result = setIn(result, 'notesModalEnabled', false)
  result = setIn(result, 'notesDisplayed', false)
  result = deleteIn(result, 'salesOrderInquiry')
  result = clearFieldsAndValues(result)
  result = clearAdditionalData(result)
  return result
}

const clearDescriptionFromId = (result, propertyName) => {
  let temp = propertyName.split('Id')
  if (temp && temp.length > 1) {
    temp = `${temp[0]}Description`
    result = setIn(result, `fields.${temp}.value`, '')
    result = setIn(result, `values.${temp}`, '')
  }
  return result
}

const getStoredAdditionalData = (additionalDataMap, rowId) => {
  const dataDefaults = {
    options: null,
    inventory: null,
    priceChange: null,
    quantityChange: null,
    substitutes: null,
    components: null,
    priceBreaks: null,
    image: null,
    johnstoneInventory: null,
    bins: null
  }

  if (!additionalDataMap || !rowId) {
    return dataDefaults
  }

  let data = getIn(additionalDataMap, rowId)
  data = data && data?.toJS ? data.toJS() : dataDefaults

  return data
}

export const getFormattedComponents = (state, row) => {
  if (!row?.rowId || !row?.lineNumber || !row?.components) {
    return null
  }

  let rowData = getIn(state, `fields.lineItemComponents.${row.rowId}.rowData`)
  rowData = rowData && rowData?.toJS ? rowData.toJS() : []
  const blankRow = {
    rowId: 'blankrow',
    lineNumber: '1',
    dataId: null,
    description: '',
    quantityExtended: null,
    quantity: null,
    parentLineNumber: row.lineNumber
  }

  if (
    rowData &&
    Array.isArray(rowData) &&
    rowData.length &&
    rowData[rowData?.length - 1]?.rowId === 'blankrow' &&
    !rowData[rowData?.length - 1]?.dataId &&
    getIn(state, 'isEditing') &&
    getIn(state, 'guid')
  ) {
    return row?.components?.length
      ? [
          ...row.components.reduce((acc, next) => {
            acc = acc.concat({
              ...next,
              rowId: next.uniqueKey,
              parentLineNumber: row.lineNumber
            })

            return acc
          }, []),
          {
            ...blankRow,
            parentLineNumber: row.lineNumber,
            lineNumber: row.components.length + 1
          }
        ]
      : null
  }

  return row?.components?.length
    ? row.components.reduce((acc, next) => {
        acc = acc.concat({
          ...next,
          rowId: next.uniqueKey,
          parentLineNumber: row.lineNumber
        })
        return acc
      }, [])
    : null
}

export const setAdditionalData = (state, row) => {
  let additionalDataMap = getIn(state, 'additionalDataMap') || fromJS({})
  row = row.toJS ? row.toJS() : row
  if (row.dataId != null) {
    const identifier = row.rowId || row.uniqueKey
    const storedData = getStoredAdditionalData(additionalDataMap, identifier)
    /* 
      most of this data is only served up by the API once, 
      and sometimes it is surprisingly removed from the DTO on
      unexpected API transactions (changing the customer etc.)
      so this was added as a safety net for that -- SVE 3/4/2021
    */
    const optionsAndAccessories = storedData?.options
      ? storedData.options
      : null

    const inventory = storedData?.inventory ? storedData.inventory : null
    const priceChange = storedData?.priceChange ? storedData.priceChange : null
    const substitutes = storedData?.substitutes ? storedData.substitutes : null
    const components = storedData?.components ? storedData.components : null
    const formattedComponents = getFormattedComponents(state, row)
    const priceBreaks = storedData?.priceBreaks ? storedData.priceBreaks : null
    const image = storedData?.image ? storedData.image : null
    const johnstoneInventory = storedData?.johnstoneInventory
      ? storedData.johnstoneInventory
      : null

    const bins = storedData?.bins ? storedData.bins : null

    additionalDataMap = setIn(
      additionalDataMap,
      identifier,
      fromJS({
        options: row.optionsAndAccessories || optionsAndAccessories,
        inventory:
          inventory &&
          inventory?.companies &&
          Array.isArray(inventory.companies)
            ? {
                ...inventory,
                ...row.inventory,
                companies:
                  inventory?.companies &&
                  Array.isArray(inventory.companies) &&
                  row?.inventory?.companies &&
                  Array.isArray(row.inventory.companies)
                    ? [
                        ...inventory.companies.filter(
                          x =>
                            !row.inventory.companies.find(
                              y => y.dataId === x.dataId
                            )
                        ),
                        ...row.inventory.companies
                      ]
                    : [...inventory.companies]
              }
            : row.inventory || inventory,
        priceChange: row.priceChange || priceChange,
        quantityChange: row.quantityChange,
        substitutes: row.substitutes || substitutes,
        components: formattedComponents || components,
        priceBreaks: row.priceBreaks || priceBreaks,
        image: row.image || image,
        johnstoneInventory: row.johnstoneInventory || johnstoneInventory,
        bins: row.bins || bins,
        serialNumberEditorData: {
          excludedSerialNumbers: row?.excludedSerialNumbers || [],
          includedSerialNumbers: row?.includedSerialNumbers || [],
          serialNumberSearchType: row?.serialNumberSearchType || 'A',
          transactionType: row?.transactionType || 'S'
        }
      })
    )

    state = setIn(state, 'additionalDataMap', additionalDataMap)

    if (
      identifier &&
      formattedComponents &&
      Array.isArray(formattedComponents)
    ) {
      state = setIn(
        state,
        `fields.lineItemComponents.${identifier}.rowData`,
        fromJS(formattedComponents)
      )
      state = setIn(
        state,
        `fields.lineItemComponents.${identifier}.isPending`,
        false
      )

      if (getIn(state, `values.lineItemComponents.${identifier}`)) {
        state = setIn(
          state,
          `values.lineItemComponents.${identifier}`,
          fromJS(formattedComponents)
        )
      }
    }
  }
  return state
}

const updateRecordFromAPI = (
  state,
  data,
  validationMessages = [],
  setPrevValue = true
) => {

  const normalizedData = Object.keys(data).reduce((acc, next) => {
    if (
      primaryTabs.includes(next) &&
      data[next] !== null &&
      typeof data[next] === 'object' &&
      Object.keys(data[next]).length
    ) {
      for (const prop in data[next]) {
        acc[prop] = data[next][prop]
      }
    } else {
      acc[next] = data[next]
    }
    return acc
  }, {})

  const recordIsLocked = normalizedData?.isLocked || false
  const guid = getIn(state, 'guid') || null

  for (const prop in normalizedData) {
    let rowData = null
    if (
      getIn(state, `fields.${prop}.grid`) &&
      normalizedData[prop] &&
      Array.isArray(normalizedData[prop])
    ) {
      rowData = getIn(state, `fields.${prop}.rowData`)
      let newGrid = normalizedData[prop]
      newGrid = newGrid.map(x => ({ ...x, rowId: x.uniqueKey }))
      let mergedData

      if (prop === 'lineItems') {
        let lineItemsEmptyRow = getIn(state, `fields.${prop}.emptyRow`)
        lineItemsEmptyRow =
          lineItemsEmptyRow && lineItemsEmptyRow?.toJS
            ? lineItemsEmptyRow.toJS()
            : emptyRow
        if (
          !newGrid.length &&
          getIn(state, `fields.${prop}.emptyRow`) &&
          guid
        ) {
          mergedData = [lineItemsEmptyRow]
        } else {
          rowData = rowData && rowData?.toJS ? rowData.toJS() : []
          mergedData = newGrid
          /* 
            got rid of LOTS of bad old logic in here that did not cover
            some very important use cases. essentially, we only need to
            worry about preserving an empty row that the user has added
            at this point, since the additionalDataMap prevents render
            thrashing in the grid and we actually have unique IDs now -- SVE 4/26/22
          */
          if (
            rowData &&
            Array.isArray(rowData) &&
            rowData.length &&
            rowData[rowData?.length - 1]?.rowId === 'blankrow' &&
            !rowData[rowData?.length - 1]?.dataId &&
            (getIn(state, 'isEditing') || recordIsLocked) &&
            guid
          ) {
            mergedData = [
              ...mergedData,
              {
                ...lineItemsEmptyRow,
                rowRetainedAfterApiTransaction: true,
                lineNumber: mergedData.length + 1
              }
            ]
          }
        }

        mergedData.forEach(x => {
          state = setAdditionalData(state, x)
          if (x?.components && Array.isArray(x.components)) {
            const components = getFormattedComponents(state, { ...x })
            components.forEach(component => {
              state = setAdditionalData(state, component)
            })
          }
        })

        rowData = fromJS(
          mergedData.map(x => {
            const {              
              ...rest
            } = x

            const components =
              getFormattedComponents(state, { ...rest }) || null

            return {
              ...rest,
              components:
                components && Array.isArray(components)
                  ? components.reduce((acc, next) => {
                      const { ...other } = next
                      acc = acc.concat({
                        ...other
                      })
                      return acc
                    }, [])
                  : null
            }
          })
        )
      } else if (prop === 'miscellaneousCharges') {
        rowData = fromJS(makeRowDataUnique(newGrid, 'rowId'))
      } else {
        rowData = fromJS(newGrid)
      }

      state = setIn(state, `fields.${prop}.rowData`, rowData)

      if (prop === 'lineItems') {
        if (
          rowData.size >= 1 &&
          rowData.get(rowData.size - 1) !== getIn(state, 'emptyRow')
        ) {
          state = setIn(state, 'isPending', false)
          state = setIn(state, `fields.${prop}.isPending`, false)
        } else {
          state = setIn(state, `fields.${prop}.isPending`, true)
        }
      } else {
        /* mainly for miscCharges grid */
        state = setIn(state, `fields.${prop}.isPending`, false)
      }
    } else {
      const notFields = ['shipments', 'lineItems', 'activities']
      if (setPrevValue && !notFields.includes(prop)) {
        const prevValue = getIn(state, `fields.${prop}.value`) || ''
        state = setIn(state, `fields.${prop}.prevValue`, fromJS(prevValue))
      }

      if (!notFields.includes(prop)) {
        const descriptionsMap = {
          branchId: 'branchDescription',
          customerId: 'customerName',
          orderedById: 'orderedByName',
          shipToId: 'shipToDescription',
          shipViaId: 'shipViaDescription',
          writerId: 'writerDescription',
          warehouseId: 'warehouseDescription'
        }
        state = setIn(
          state,
          `fields.${prop}.value`,
          fromJS(normalizedData[prop])
        )

        if (
          (prop && descriptionsMap[prop]) ||
          (prop && prop.replace('Id', 'Description')) ||
          (prop && prop.replace('Id', 'Name'))
        ) {
          const descriptionKey =
            descriptionsMap[prop] ||
            prop.replace('Id', 'Description') ||
            prop.replace('Id', 'Name')

          /* set or unset any descriptions as needed per the API response -- SVE 6/29/2020 */
          if (descriptionKey) {
            const description =
              normalizedData[descriptionKey] === null
                ? ''
                : normalizedData[descriptionKey]
            state = setIn(
              state,
              `fields.${prop}.description`,
              fromJS(description)
            )
          }
        }
      }

      if (is.array(validationMessages) && validationMessages.length) {
        let validationMessage = validationMessages.find(
          x => toCamelCase(x.key) === prop
        )
        validationMessage =
          validationMessage && validationMessage.value
            ? validationMessage.value
            : ''
        state = setIn(state, `fields.${prop}.errorMessage`, validationMessage)
      } else if (!notFields.includes(prop)) {
        state = setIn(state, `fields.${prop}.errorMessage`, '')
      }
    }
    if (prop === 'activities') {
      if (normalizedData[prop] && Array.isArray(normalizedData[prop])) {
        state = setIn(state, `values.${prop}`, fromJS(normalizedData[prop]))
      }
    } else {
      const gridsWithRowIds = ['lineItems', 'miscellaneousCharges']
      const resolvedValue =
        rowData && gridsWithRowIds.includes(prop)
          ? rowData
          : fromJS(normalizedData[prop])
      state = setIn(state, `values.${prop}`, resolvedValue)
    }
  }

  if (normalizedData?.customFields?.customFieldsData) {
    const fields = getIn(state, 'fields').toJS()
    const fieldKeys = Object.keys(fields)

    const customFieldsKeys = fieldKeys.filter(x => x.includes('customFields-'))

    for (const key of customFieldsKeys) {
      const field = fields[key]

      const dataId = field?.meta?.dataId
      const custField = normalizedData?.customFields?.customFieldsData.find(
        x => x.dataId === dataId
      )

      if (custField) {
        field.value = custField.value
        state = setIn(state, `fields.${key}`, fromJS(field))
      }
    }
  }
  /* now store an array of validation messages for focus in searchArea */
  const formattedValidationMessages =
    validationMessages && validationMessages.length
      ? validationMessages.reduce((acc, next) => {
          const field = next.key ? toCamelCase(next.key) : ''
          acc = acc.concat({
            ...next,
            key: field
          })
          return acc
        }, [])
      : []

  state = setIn(
    state,
    'values.validationMessages',
    fromJS(formattedValidationMessages)
  )

  // set validation for tendered field manually since it is not returns by API
  // find more efficient way later
  // -- LL 1/14/20
  formattedValidationMessages.forEach(x => {
    if (x.key === 'tendered') {
      state = setIn(state, 'fields.tendered.errorMessage', x.value)
    }
  })

  state = handleSecondaryTabData(data, state)
  return state
}

export const initMiscChargesGrid = (state, isPending = false) => {
  /* miscCharges grid has not been registered in some cases */
  /*
    TEMP: for EditableGrid,
    need this in place until EditableGrid is sunsetted
 */
  state = setIn(
    state,
    'fields.miscellaneousCharges.emptyRow',
    fromJS({
      dataId: null,
      description: '',
      amount: 0,
      taxable: false
    })
  )

  state = setIn(state, 'fields.miscellaneousCharges.grid', true)
  state = setIn(state, 'fields.miscellaneousCharges.isPending', isPending)

  return state
}

export const updateCustomerPartNumber = (state, payload) => {
  let result = state
  let lineItems
  const rowData = getIn(state, 'fields.lineItems.rowData')
  const newLineItems = payload?.lineItems || []

  if (rowData && rowData.toJS && newLineItems?.length) {
    /* 
      there is probably a better way to do this 
      but I hesitate to change it without seeing other possible
      implemenations of this feature
    */
    const changedLineItemsDict = newLineItems.reduce((acc, next, i) => {
      acc[next.uniqueKey] = i

      return acc
    }, {})

    lineItems = rowData.toJS().reduce((acc, next) => {
      if (is.number(changedLineItemsDict[next.uniqueKey])) {
        const idx = changedLineItemsDict[next.uniqueKey]
        acc = acc.concat({
          ...next,
          comments: newLineItems[idx]?.comments,
          customerPartNumber: newLineItems[idx].customerPartNumber
        })
      } else {
        acc = acc.concat({ ...next })
      }
      return acc
    }, [])

    result = setIn(result, 'fields.lineItems.rowData', fromJS(lineItems))
  }

  return result
}

export const onFetchAdditionalDataSuccess = (
  state,
  payload,
  isWarehouseFetch = false
) => {
  if (payload?.lineNumber) {
    let lineItems = getIn(state, 'fields.lineItems.rowData')
    let rowIndex
    if (payload?.childLineNumber && payload?.parentLineNumber) {
      //
      rowIndex = lineItems.findIndex(
        x => x.get('lineNumber') === payload.parentLineNumber
      )
    } else {
      rowIndex = lineItems.findIndex(
        x => x.get('lineNumber') === payload.lineNumber
      )
    }
    const { ...rest } = payload
    if (!isWarehouseFetch) {
      /* do NOT update the lineItem data if we are just getting warehouse data */
      /* mergeDeepIn is NOT working for comments for some reason */
      if (!payload?.childLineNumber || !payload?.parentLineNumber) {
        lineItems = lineItems.mergeIn([rowIndex], rest)
        lineItems = lineItems.update(rowIndex, data =>
          data.set('hasAdditionalData', false)
        )
        const lineItem = lineItems.get(rowIndex)
        if (lineItem?.get('components')) {
          lineItem.get('components').forEach(component => {
            state = setAdditionalData(state, component)
          })
        }
      } else {
        let item = lineItems.find(
          x => x.get('lineNumber') === payload.parentLineNumber
        )
        let components = item.get('components')
        components = components.reduce((acc, next) => {
          if (next.get('lineNumber') === payload.childLineNumber) {
            acc = acc.push(fromJS(rest))
          } else {
            acc = acc.push(next)
          }
          return acc
        }, List())
        item = item.set('components', components)
        item = item.set('hasAdditionalData', false)
        lineItems = lineItems.mergeIn([rowIndex], item)
      }
    }

    const rowId = lineItems.get(rowIndex).get('rowId')

    const hasBlankRow = lineItems.find(x => x.get('rowId') === 'blankrow')
    if (hasBlankRow) {
      /* ensure that a previously added blank row is flagged to prevent focus on it */
      const blankRowIndex = lineItems.findIndex(
        x => x.get('rowId') === 'blankrow'
      )

      if (blankRowIndex && is.number(blankRowIndex)) {
        lineItems = lineItems.update(blankRowIndex, data =>
          data.set('rowRetainedAfterApiTransaction', true)
        )
      }
    }

    state = setIn(state, 'fields.lineItems.rowData', lineItems)
    state = setIn(state, 'values.lineItems', lineItems)
    state = setIn(state, 'fields.lineItems.selectedRowIndex', rowIndex)
    state = payload?.childLineNumber
      ? setAdditionalData(state, { ...rest })
      : setAdditionalData(state, { ...rest, rowId })
    // }
  }

  state = setIn(state, 'isFetchingAdditionalData', false)
  return state
}

export default {
  [CONSTANTS.SET_SEARCH_AREA_EXPANSION_PANEL_STATE]: (
    state,
    { payload: { expanded } }
  ) => {
    let result = state
    result = setIn(result, 'searchAreaExpanded', expanded)
    return result
  },
  [CONSTANTS.SET_PRODUCT_DATA_DEFAULTS]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    let rowData = getIn(result, rowDataKey)

    if (rowData.size) {
      rowData = rowData.update(rowData.size - 1, data => {
        return data.set('lineNumber', rowData.size)
      })
    }

    result = setIn(result, rowDataKey, rowData)

    return result
  },
  [CONSTANTS.GET_PRODUCT_DETAILS.REQUEST]: state => {
    let result = state

    result = setIn(result, 'fields.lineItems.grid', true)
    result = setIn(result, 'fields.miscellaneousCharges.grid', true)
    /* temp fix for CLOSE_SEARCH error^^ */

    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.GET_PRODUCT_DETAILS.SUCCESS]: state => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.GET_PRODUCT_DETAILS.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.CONFIRM_PROVISIONAL_CHANGES.REQUEST]: state => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.CONFIRM_PROVISIONAL_CHANGES.FAILURE]: state => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.CANCEL_PROVISIONAL_CHANGES.REQUEST]: state => {
    let result = state
    result = setIn(result, 'isPosting', true)
    result = deleteIn(result, 'rebateCostCleared')
    return result
  },
  [CONSTANTS.CANCEL_PROVISIONAL_CHANGES.FAILURE]: state => {
    let result = state
    result = setIn(result, 'isPosting', false)
    result = deleteIn(result, 'rebateCostCleared')
    return result
  },
  [CONSTANTS.ON_PROPERTY_CHANGE.REQUEST]: state => {
    let result = state
    /* temp fix for CLOSE_SEARCH error */
    result = setIn(result, 'fields.lineItems.grid', true)
    result = setIn(result, 'fields.miscellaneousCharges.grid', true)
    result = setIn(result, 'isPosting', true)
    return result
  },
  [INDEX_SEARCH_CONSTANTS.CLOSE_SEARCH]: state => {
    let result = state
    /* temp fix for CLOSE_SEARCH error */
    result = setIn(result, 'fields.lineItems.grid', true)
    result = setIn(result, 'fields.miscellaneousCharges.grid', true)
    return result
  },
  [CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS]: (
    state,
    {
      payload: {
        activities = [],
        additionalDataAvailable = false,
        audits = [],
        record,
        validationMessages = [],
        propertyChanged,
        lineNumber
      }
    }
  ) => {
    let result = state

    result = initMiscChargesGrid(result)
    /* temp for MiscCharge editableGrid */

    if (record?.isLocked) {
      result = setIn(result, 'hasRecord', true)
      result = setIn(result, 'isEditing', true)
    }

    if (propertyChanged === 'dataId') {
      // if inquiring an order, set isEditing to false and hasRecord to true
      // otherwise, any other fields, editing is true
      const isEditing = record?.isLocked || false
      result = setIn(result, 'hasRecord', true)
      result = setIn(result, 'isEditing', isEditing)
    }

    result = updateRecordFromAPI(result, record, validationMessages)
    if (record.dataId) {
      const previousOrders = getIn(result, 'previousOrders') || Set()
      if (!previousOrders.size) {
        result = setIn(result, 'previousOrders', previousOrders)
      }
      result = setIn(
        result,
        'previousOrders',
        previousOrders.add(record.dataId)
      )
    }

    if (
      (propertyChanged === 'customerId' || propertyChanged === 'dataId') &&
      record?.final
    ) {
      /* this is important, do not remove */
      const pos = record?.final?.pos || false
      const paymentOption = pos ? 'cash' : 'bill'
      result = setIn(result, 'ui.paymentOption', paymentOption)
    }

    if (record?.shipToAddress) {
      /* API sends back header.shipToAddress as null sometimes even though it comes in the root */
      result = setIn(
        result,
        'values.shipToAddress',
        fromJS(record.shipToAddress)
      )
    }

    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.ON_PROPERTY_CHANGE.FAILURE]: state => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.PROPERTY_REFRESH.SUCCESS]: (
    state,
    { payload: { record, validationMessages } }
  ) => {
    let result = state
    result = updateRecordFromAPI(result, record, validationMessages, false)
    return result
  },
  [CONSTANTS.UPDATE_VALIDATION_MESSAGES]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state

    if (getIn(result, `fields.${propertyName}.value`)) {
      result = setIn(result, `fields.${propertyName}.errorMessage`, '')
    }

    let validationMessages = getIn(result, 'values.validationMessages')
    validationMessages =
      validationMessages && validationMessages.toJS
        ? validationMessages.toJS()
        : []
    if (validationMessages && validationMessages.length) {
      validationMessages = validationMessages.reduce((acc, next) => {
        if (next.key && next.key !== propertyName) {
          acc = acc.concat(next)
        }
        return acc
      }, [])

      result = setIn(
        result,
        'values.validationMessages',
        fromJS(validationMessages)
      )
    }

    return result
  },
  [CONSTANTS.SET_INTERNAL_NOTES]: (state, { payload: { record } }) => {
    let result = state
    /* ddiForm flag */
    result = setIn(result, 'notesDisplayed', true)
    return result
  },
  [CONSTANTS.UNSET_INTERNAL_NOTES]: (state, { payload: { record } }) => {
    let result = state

    /* ddiForm flag */
    result = setIn(result, 'notesDisplayed', false)
    return result
  },
  [CONSTANTS.SET_INTERNAL_NOTES_VIEWED]:(state, { payload: { record } }) => {
    let result = state
    result = setIn(result, 'values.internalNotesViewed', record)
    return result;
  },
  [CONSTANTS.UPDATE_FIELD_AND_DESCRIPTION]: (
    state,
    { payload: { propertyName, value, descriptionField, description } }
  ) => {
    let result = state

    if (propertyName) {
      result = setIn(result, `fields.${propertyName}.value`, value)
      result = setIn(result, `values.${propertyName}`, value)
    }

    if (descriptionField) {
      result = setIn(result, `fields.${descriptionField}.value`, description)
      result = setIn(result, `values.${descriptionField}`, description)
    }

    return result
  },
  [CONSTANTS.REMOVE_BOX_QUANTITY_FLAG]: (
    state,
    { payload: { lineNumber } }
  ) => {
    let result = state
    /* 
      not sure this reducer method is used anymore anywhere,
      and the logic of removeSuppressedReference function 
      no longer applies I don't think now that we actually have
      rowIds
      -- SVE 3/5/21 
    */
    let suppressedLineItems = getIn(
      result,
      'values.listOfLineItemIdsSuppressed'
    )
    suppressedLineItems =
      suppressedLineItems && suppressedLineItems?.toJS
        ? suppressedLineItems.toJS()
        : []

    if (
      suppressedLineItems.length &&
      suppressedLineItems.includes(lineNumber)
    ) {
      const newLineItems = removeSuppressedReference(
        suppressedLineItems,
        lineNumber
      )
      result = setIn(
        result,
        'values.listOfLineItemIdsSuppressed',
        fromJS(newLineItems)
      )
    }

    return result
  },
  [CONSTANTS.SUPPRESS_BOX_QUANTITY_PROMPT]: (state, { payload: { rowId } }) => {
    let result = state
    let suppressedLineItems = getIn(
      result,
      'values.listOfLineItemIdsSuppressed'
    )
    suppressedLineItems =
      suppressedLineItems && suppressedLineItems?.toJS
        ? suppressedLineItems.toJS()
        : []

    if (is.array(suppressedLineItems)) {
      suppressedLineItems.push(rowId)
    }

    result = setIn(
      result,
      'values.listOfLineItemIdsSuppressed',
      fromJS(suppressedLineItems)
    )

    return result
  },
  [CONSTANTS.SET_SALES_ORDER_INITIALIZED]: (state, action) => {
    let result = state
    result = setIn(result, 'values.salesOrderInitialized', true)
    return result
  },
  ...editableGridBehaviors,
  [DDICONSTANTS.ADD_BLANK_ROW]: (state, { payload: { propertyName } }) => {
    return state
  },
  [DDIMASTER_CONSTANTS.CANCEL_EDIT.SUCCESS]: (state, { payload }) => {
    let result = state
    /* set isEditing false first before calling updateRecordFromAPI */
    result = setIn(result, 'isEditing', payload.isLocked)

    if (payload && !payload.dataId) {
      result = clearSalesOrder(result)

      if (getIn(result, 'guid')) {
        result = setIn(result, 'fields.lineItems.rowData', fromJS([emptyRow]))
      } else {
        /* no 'blankrow' rows on mobile */
        result = setIn(result, 'fields.lineItems.rowData', fromJS([]))
        result = setIn(result, 'values.lineItems', fromJS([]))
      }
    } else {
      result = clearAdditionalData(result)
      result = updateRecordFromAPI(result, payload)
    }

    return result
  },
  [CONSTANTS.VALIDATE_SALES_ORDER.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'values.valid', false)
    result = deleteIn(result, 'fields.manualDataId')
    result = deleteIn(result, 'values.manualDataId')
    result = setIn(result, 'isSaving', false)

    if (
      action?.payload?.message &&
      action?.payload?.message === 'Serial Number Input Required'
    ) {
      result = setIn(result, 'values.serialNumberIsIncomplete', true)
    }
    return result
  },
  [CONSTANTS.SAVE_SALES_ORDER.TRY]: (state, action) => {
    let result = state
    result = setIn(result, 'isSaving', true)
    return result
  },
  [CONSTANTS.SAVE_SALES_ORDER_CANCEL]: (state, action) => {
    let result = state
    result = setIn(result, 'isSaving', false)
    return result
  },
  [CONSTANTS.SAVE_SALES_ORDER.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'values.valid', false)

    result = deleteIn(result, 'fields.manualDataId')
    result = deleteIn(result, 'values.manualDataId')
    result = setIn(result, 'isSaving', false)
    return result
  },
  [CONSTANTS.SAVE_SALES_ORDER.SUCCESS]: (
    state,
    { payload: { record, validationMessages = [], hasBlankRow = false } }
  ) => {
    let result = state

    if (hasBlankRow) {
      const lineItems = getIn(result, 'fields.lineItems.rowData')
      const newLineItems = lineItems.filter(
        item => item.get('rowId') !== 'blankrow'
      )

      result = setIn(result, 'fields.lineItems.rowData', newLineItems)
    }

    /* set isEditing false first before calling updateRecordFromAPI */
    result = setIn(result, 'isEditing', false)
    result = updateRecordFromAPI(result, record, validationMessages)

    /* need to flag values.valid false so that the order gets re-validated */
    result = setIn(result, 'values.valid', false)
    result = deleteIn(result, 'fields.manualDataId')
    result = deleteIn(result, 'values.manualDataId')
    result = setIn(result, 'isSaving', false)

    /* prevent ddiForm from showing the notes here */
    result = setIn(result, 'notesDisplayed', true)
    return result
  },
  [CONSTANTS.CLEAR_MANUAL_DATA_ID]: (state, { isCheckout }) => {
    let result = state
    result = setIn(result, 'values.valid', false)
    result = deleteIn(result, 'fields.manualDataId')
    result = deleteIn(result, 'values.manualDataId')

    /* this modal could be triggered from on top of an overpayment in Checkout */
    if (isCheckout) {
      result = resetPaymentModalFlags(result)
    }

    return result
  },
  [INDEX_SEARCH_CONSTANTS.FOUND_INVALID_VALUES_REJECTED]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state
    result = clearDescriptionFromId(result, propertyName)
    return result
  },
  [INDEX_SEARCH_CONSTANTS.BLUR]: (
    state,
    { payload: { propertyName, isSet } }
  ) => {
    let result = state

    if (propertyName === 'jobId' && !isSet) {
      result = clearDescriptionFromId(result, propertyName)
    }

    return result
  },
  [CONSTANTS.VALIDATE_SALES_ORDER.SUCCESS]: (
    state,
    { payload: { record, validationMessages = [] } }
  ) => {
    let result = state
    result = updateRecordFromAPI(result, record, validationMessages)
    return result
  },
  [CONSTANTS.CLEAR_ORDER]: (state, { payload: { retainDataId } }) => {
    let result = state
    const dataId = getIn(result, 'fields.dataId.value')

    result = clearSalesOrder(result)

    if (dataId && retainDataId) {
      result = setIn(result, 'fields.dataId.value', dataId)
      result = setIn(result, 'values.dataId', dataId)
    }

    result = setIn(result, 'fields.lineItems.rowData', fromJS([emptyRow]))

    return result
  },
  [CONSTANTS.UPDATE_GRID_ITEM.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingGridUpdate', true)
    return result
  },
  [CONSTANTS.UPDATE_GRID_ITEM.SUCCESS]: (state, action) => {
    const {
      payload: { record, validationMessages = [] }
    } = action

    let result = state
    result = updateRecordFromAPI(result, record, validationMessages)

    if (record?.shipToAddress) {
      /* API sends back header.shipToAddress as null sometimes even though it comes in the root */
      result = setIn(
        result,
        'values.shipToAddress',
        fromJS(record.shipToAddress)
      )
    }

    result = setIn(result, 'isPostingGridUpdate', false)
    return result
  },
  [CONSTANTS.UPDATE_GRID_ITEM.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingGridUpdate', false)
    return result
  },
  [CONSTANTS.REMOVE_GRID_ITEM.SUCCESS]: (state, { payload }) => {
    let result = state

    const { validationMessages, ...other } = payload
    result = updateRecordFromAPI(
      result,
      { ...other },
      validationMessages,
      false
    )

    return result
  },
  [CONSTANTS.OPEN_COMMENTS.SUCCESS]: (
    state,
    { payload: { comment, description } }
  ) => {
    let result = state

    result = setIn(result, 'fields.comment.value', comment)
    result = setIn(result, 'values.comment', comment)

    return result
  },
  [CONSTANTS.SET_COMMENT.SUCCESS]: (
    state,
    { payload: { comments, uniqueKey } }
  ) => {
    let result = state

    result = setIn(
      result,
      `fields.lineItems.rowData[${uniqueKey - 1}].comments`,
      fromJS(comments)
    )

    return result
  },
  [DDIMASTER_CONSTANTS.LOCK_FOR_EDIT.REQUEST]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.LOCK_FOR_EDIT.REQUEST](
      result,
      action
    )

    /*
      we should not be setting isEditing to true on the request
      at least in Sales Order. -- SVE 11/5/2020
    */
    result = setIn(result, 'isEditing', false)
    return result
  },
  [DDIMASTER_CONSTANTS.LOCK_FOR_EDIT.SUCCESS]: (state, { payload }) => {
    let result = state
    const { validationMessages, ...other } = payload

    /* miscCharges grid has not been registered in some cases */
    result = initMiscChargesGrid(result)

    /* ddiForm lockForEditSuccess doesn't get stuff */
    result = setIn(result, 'isEditing', payload.isLocked)
    result = updateRecordFromAPI(
      result,
      { ...other },
      validationMessages,
      false
    )

    if (payload?.shipToAddress) {
      /* API sends back header.shipToAddress as null sometimes even though it comes in the root */
      result = setIn(
        result,
        'values.shipToAddress',
        fromJS(payload.shipToAddress)
      )
    }

    return result
  },
  [CONSTANTS.LOCK_SALES_ORDER_NOTES.SUCCESS]: state => {
    let result = state
    result = setIn(result, 'notesModalEnabled', true)
    return result
  },
  [CONSTANTS.CANCEL_SALES_ORDER_NOTES_EDIT.SUCCESS]: state => {
    let result = state
    result = setIn(result, 'notesModalEnabled', false)
    return result
  },
  [CONSTANTS.CLOSE_SALES_ORDER.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'isEditing', false)
    return result
  },
  [CONSTANTS.SEND_SALES_ORDER_DOCUMENT.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'values.isSendRequired', false)
    return result
  },
  [CONSTANTS.STORE_UI_FEATURE_STATE]: (
    state,
    { payload: { feature, featureState } }
  ) => {
    let result = state
    result = setIn(result, `ui.${feature}`, featureState)
    return result
  },
  [CONSTANTS.CREATE_NEW.SUCCESS]: (state, { payload: { record } }) => {
    let result = state
    if (record) {
      /* running clearSalesOrder here is super super important -- SVE 1/27/21 */
      result = clearSalesOrder(result)
      result = updateRecordFromAPI(result, record, record.validationMessages)

      result = setIn(result, 'fields.miscellaneousCharges.isPending', false)

      result = setIn(result, 'fields.lineItems.isPending', true)
      result = setIn(result, 'fields.lineItems.rowData', fromJS([emptyRow]))

      if (!record.customerId) {
        result = setIn(result, 'hasRecord', false)
        result = setIn(result, 'isEditing', false)
        result = deleteIn(result, 'salesOrderInquiry')
      } else {
        result = setIn(result, 'hasRecord', true)
        result = setIn(result, 'isEditing', true)
      }
    } else {
      result = clearSalesOrder(result)

      result = setIn(result, 'fields.miscellaneousCharges.isPending', false)

      result = setIn(result, 'fields.lineItems.isPending', true)
      result = setIn(result, 'fields.lineItems.rowData', fromJS([emptyRow]))

      result = setIn(result, 'hasRecord', false)
      result = setIn(result, 'isEditing', false)
    }

    return result
  },
  [CONSTANTS.CANCEL_NEW_SALES_ORDER_EDIT.SUCCESS]: (
    state,
    { payload: { record } }
  ) => {
    let result = state

    result = clearSalesOrder(result)

    result = initMiscChargesGrid(result)

    result = setIn(result, 'fields.lineItems.isPending', true)
    result = setIn(result, 'fields.lineItems.rowData', fromJS([emptyRow]))

    return result
  },
  [CONSTANTS.SAVE_SALES_ORDER_NOTES.SUCCESS]: (state, { payload }) => {
    let result = state
    /* is this right? */
    // result = setIn(result, 'isEditing', false)
    /* ^^no, this was a bug -- SVE 9/10/2021 */
    result = setIn(result, 'notesModalEnabled', false)
    return result
  },
  [CONSTANTS.DELETE_SALES_ORDER_NOTES.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'fields.internalNotes.value', '')
    return result
  },
  [CONSTANTS.CLEAR_REBATE.SUCCESS]: (
    state,
    { payload: { record, lineNumber, validationMessages = [] } }
  ) => {
    let result = state
    result = updateRecordFromAPI(result, record, validationMessages)
    let priceChange = getIn(
      result,
      `additionalDataMap.${lineNumber}.priceChange`
    )
    const rebateFields = [
      'rebateCost',
      'rebateVendorId',
      'rebateVendorDescription',
      'rebateContractId',
      'rebateCostMethod'
    ]

    rebateFields.forEach(field => {
      result = setIn(
        result,
        `values.rebateCostData[${lineNumber - 1}].${field}`,
        priceChange[field]
      )
      result = setIn(
        result,
        `fields.rebateCostData[${lineNumber - 1}].${field}.value`,
        priceChange[field]
      )
    })
    priceChange = priceChange.toJS()
    priceChange = {
      ...priceChange,
      rebateCost: null,
      rebateVendorId: null,
      rebateContractId: null,
      rebateVendorDescription: null
    }

    result = setIn(
      result,
      `fields.lineItems.rowData[${lineNumber - 1}].rebateCost`,
      null
    )
    result = setIn(
      result,
      `values.lineItems[${lineNumber - 1}].rebateCost`,
      null
    )
    result = setIn(
      result,
      `values.lineItems[${lineNumber - 1}].rebateCostCleared`,
      true
    )
    result = setIn(
      result,
      `additionalDataMap.${lineNumber}.priceChange`,
      fromJS(priceChange)
    )
    result = setIn(result, 'rebateCostCleared', true)
    return result
  },
  [CONSTANTS.ADD_OPTIONS.TRY]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.ADD_OPTIONS.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    result = setIn(result, 'isAddingBulkLineItems', true)
    return result
  },
  [CONSTANTS.ADD_OPTIONS.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    result = setIn(result, 'isAddingBulkLineItems', false)
    return result
  },
  [CONSTANTS.ADD_OPTIONS.SUCCESS]: (
    state,
    { payload: { record, validationMessages = [] } }
  ) => {
    let result = state
    result = updateRecordFromAPI(result, record, validationMessages)
    result = setIn(result, 'hasRecord', true)
    result = setIn(result, 'isEditing', true)
    result = setIn(result, 'isPosting', false)
    result = setIn(result, 'isAddingBulkLineItems', false)
    return result
  },
  [CONSTANTS.READ_LINE_ITEM.REQUEST]: (state, action) => {
    let result = state
    /* this flag is mainly for lazy loading images in mobile */
    result = setIn(result, 'isFetchingAdditionalData', true)
    return result
  },
  [CONSTANTS.READ_LINE_ITEM.SUCCESS]: (state, { payload }) => {
    let result = state
    result = onFetchAdditionalDataSuccess(result, payload)
    return result
  },
  [CONSTANTS.READ_LINE_ITEM.FAILURE]: (state, action) => {
    let result = state
    /* this flag is mainly for lazy loading images in mobile */
    result = setIn(result, 'isFetchingAdditionalData', false)
    return result
  },
  [INDEX_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state

    /*
      Marc -- this fixes an issue with index search
      and setting the description onBlur (components/Search/IndexSearch/behaviors)
      SVE 10/8/19
    */
    result = setIn(result, `fields.${propertyName}.isFetching`, false)

    return result
  },
  [CONSTANTS.ADD_EDITABLE_GRID_ROW]: (state, { payload: { propertyName } }) => {
    /* temp for Misc Changes grid or it won't work */
    let result = state
    let grid = getIn(result, `fields.${propertyName}`)
    if (grid && getIn(grid, 'emptyRow')) {
      const isPending = getIn(grid, 'isPending')
      if (!isPending) {
        grid = setIn(grid, 'isPending', true)
      } else {
        return result
      }

      const empty = getIn(grid, 'emptyRow')
      let rowData = getIn(grid, 'rowData') || fromJS([])
      rowData = rowData.push(fromJS(empty))
      grid = setIn(grid, 'rowData', rowData)
      result = setIn(result, `fields.${propertyName}`, grid)
    }
    return result
  },
  [CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.TRY]: (state, action) => {
    let result = state
    /* this flag is important */
    result = setIn(result, 'isPostingProvisionalData', true)
    return result
  },
  [CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.REQUEST]: (state, action) => {
    let result = state
    /* this flag is important */
    result = setIn(result, 'isPostingProvisionalData', true)
    return result
  },
  [CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.SUCCESS]: (
    state,
    { payload: { lineNumber, parentLineNumber, propertyName, record } }
  ) => {
    let result = state
    /* this flag is important */
    result = setIn(result, 'isPostingProvisionalData', false)
    result = updateRecordFromAPI(result, record)

    if (propertyName && propertyName?.match(/rebate/)) {
      const rebateFields = [
        'rebateCost',
        'rebateVendorId',
        'rebateVendorDescription',
        'rebateContractId',
        'rebateCostMethod'
      ]

      const priceChange = parentLineNumber
        ? record?.detail?.lineItems
            ?.find(x => x?.lineNumber === parentLineNumber)
            ?.find(x => x.lineNumber === lineNumber)?.priceChange
        : record?.detail?.lineItems?.find(x => x?.lineNumber === lineNumber)
            ?.priceChange

      const rowIndex = parentLineNumber
        ? record?.detail?.lineItems
            ?.find(x => x?.lineNumber === parentLineNumber)
            ?.findIndex(x => x.lineNumber === lineNumber)
        : record?.detail?.lineItems?.findIndex(
            x => x?.lineNumber === lineNumber
          )

      if (priceChange && is.number(rowIndex)) {
        rebateFields.forEach(field => {
          result = setIn(
            result,
            `values.rebateCostData[${rowIndex}].${field}`,
            priceChange[field]
          )
          result = setIn(
            result,
            `fields.rebateCostData[${rowIndex}].${field}.value`,
            priceChange[field]
          )
        })
      }
    }
    return result
  },
  [CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.FAILURE]: (state, action) => {
    let result = state
    /* this flag is important: FOR 497 access override cancellation */
    result = setIn(result, 'isPostingProvisionalData', false)
    return result
  },
  [DDIMASTER_CONSTANTS.GET_ENTITY.REQUEST]: (state, action) => {
    let result = state

    /*
      if these fields are not flagged as grids,
      their data will not get properly populated
      in updateRecordFromAPI function -- SVE 11/20/2019
    */
    result = setIn(result, 'fields.activities.grid', true)
    result = setIn(result, 'fields.attachments.grid', true)
    result = setIn(result, 'fields.audits.grid', true)
    result = setIn(result, 'fields.miscellaneousCharges.grid', true)

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.GET_ENTITY.REQUEST](
      result,
      action
    )

    return result
  },
  [DDIMASTER_CONSTANTS.GET_ENTITY.SUCCESS]: (state, action) => {
    let result = state

    result = setIn(result, 'fields.activities.grid', true)
    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.GET_ENTITY.SUCCESS](
      result,
      action
    )

    if (
      action.payload.detail ||
      action.payload.header ||
      action.payload.final ||
      action.payload.shipments
    ) {
      /*
        need to assign the rowId on changing tabs here, which is handled
        in updateRecordFromAPI (the grid parsing routine in there should get cleaned up)
        AND get stored validationMessages from Redux to ensure the validation
        stays in place. Otherwise it is lost on changing tabs, because this data
        only comes from propertyChange, not 'read' -- SVE 1/9/20
      */
      let validationMessages = getIn(result, 'values.validationMessages')
      validationMessages =
        validationMessages && validationMessages.toJS
          ? validationMessages.toJS()
          : []

      /*
        for screen opening workflows, need to flag lineItems.grid true
        and set selectedRowIndex to 0 in order to get the sidebar data
        to show up via updateRecordFrom API -- SVE 4/24/2020
      */
      if (!getIn(result, 'fields.lineItems.selectedRowIndex')) {
        result = setIn(result, 'fields.lineItems.selectedRowIndex', 0)
      }

      if (!getIn(result, 'fields.lineItems.grid')) {
        result = setIn(result, 'fields.lineItems.grid', true)
      }
      result = updateRecordFromAPI(result, action.payload, validationMessages)
    } else {
      result = handleSecondaryTabData(action.payload, result)
    }

    const customerId = getIn(result, 'fields.customerId.value')
    const lineItems = getIn(result, 'fields.lineItems.rowData')
    const firstRowId =
      lineItems && lineItems.get && lineItems.get(0)
        ? lineItems.get(0).get('rowId')
        : null
    const lineItemsGridBlank = firstRowId === 'blankrow'
    if (!customerId && lineItemsGridBlank) {
      result = setIn(result, 'hasRecord', false)
    }

    if (action?.payload?.shipToAddress) {
      /* API sends back header.shipToAddress as null sometimes even though it comes in the root */
      result = setIn(
        result,
        'values.shipToAddress',
        fromJS(action.payload.shipToAddress)
      )
    }

    return result
  },
  [CONSTANTS.COPY_ORDER.SUCCESS]: (state, { payload }) => {
    let result = state
    result = handleCopyOrderData(result, payload)
    return result
  },
  [CONSTANTS.COPY_PRODUCTS.SUCCESS]: (state, { payload }) => {
    let result = state
    result = handleCopyOrderData(result, payload)
    return result
  },
  [CONSTANTS.CONVERT_QUOTE_TO_ORDER.SUCCESS]: (state, { payload }) => {
    let result = state
    result = handleCopyOrderData(result, payload)
    return result
  },
  [CONSTANTS.CHANGE_COPY_ORDER_OPTION.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'orderOptionIsPosting', true)
    return result
  },
  [CONSTANTS.CHANGE_COPY_ORDER_OPTION.FAILURE]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state
    result = setIn(result, 'orderOptionIsPosting', false)
    /* this is for 497 access failure */
    const mayRequireAccessCheck = ['price', 'costOverrides', 'taxable']
    if (mayRequireAccessCheck.includes(propertyName)) {
      result = setIn(
        result,
        `fields.copyProducts.${propertyName}.value`,
        getIn(result, `fields.copyProducts.${propertyName}.prevValue`)
      )
    }
    return result
  },
  [CONSTANTS.CHANGE_COPY_ORDER_OPTION.SUCCESS]: (state, { payload }) => {
    let result = state
    result = handleCopyOrderData(result, payload)
    result = setIn(result, 'orderOptionIsPosting', false)
    return result
  },
  [CONSTANTS.EXECUTE_COPY_ORDER.SUCCESS]: (state, { payload }) => {
    let result = state

    const { validationMessages, ...other } = payload

    if (payload?.copyOrderType && payload?.copyOrderType === 'copyOrder') {
      result = clearAdditionalData(result)
    }

    result = updateRecordFromAPI(
      result,
      { ...other },
      validationMessages,
      false
    )

    result = setIn(result, 'isEditing', true)
    result = setIn(result, 'hasRecord', true)

    /* set flag to allow modals on corresponding value updates */
    result = setIn(result, 'values.salesOrderInitialized', true)

    result = deleteIn(result, 'fields.copyProducts')
    result = deleteIn(result, 'values.copyProducts')
    return result
  },
  [CONSTANTS.CANCEL_COPY_PRODUCTS.SUCCESS]: (state, action) => {
    let result = state

    result = deleteIn(result, 'fields.copyProducts')
    result = deleteIn(result, 'values.copyProducts')
    return result
  },
  [CONSTANTS.CLEAR_COPY_ORDER_DATA]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.copyProducts.lineItems.rowData', fromJS([]))
    return result
  },
  [CONSTANTS.VOID_SALES_ORDER.TRY]: (state, { payload: { preSave } }) => {
    let result = state

    if (preSave) {
      result = setIn(result, 'isPosting', true)
    }
    return result
  },
  [CONSTANTS.VOID_SALES_ORDER.SUCCESS]: (state, { payload }) => {
    let result = state

    result = deleteIn(result, 'fields.userId')
    result = clearSalesOrder(result)
    result = setIn(result, 'isPosting', false)

    return result
  },
  [CONSTANTS.VOID_SALES_ORDER.FAILURE]: (state, { payload: { clearId } }) => {
    let result = state

    if (clearId) {
      result = deleteIn(result, 'fields.userId')
    }
    result = setIn(result, 'fields.reason.value', '')
    result = setIn(result, 'fields.reason.prevValue', '')
    result = setIn(result, 'isPosting', false)

    return result
  },
  [CONSTANTS.CANCEL_SALES_ORDER_EDIT.SUCCESS]: (
    state,
    { payload: { record } }
  ) => {
    let result = state
    /* set isEditing false first before calling updateRecordFromAPI */
    result = setIn(result, 'isEditing', false)

    if (record) {
      result = clearAdditionalData(result)
      result = updateRecordFromAPI(result, record)
    }

    if (record?.shipToAddress) {
      /* API sends back header.shipToAddress as null sometimes even though it comes in the root */
      result = setIn(
        result,
        'values.shipToAddress',
        fromJS(record.shipToAddress)
      )
    }

    return result
  },
  [CONSTANTS.GET_FAST_PRODUCT_META.SUCCESS]: (state, { payload }) => {
    let result = state

    for (const prop in payload) {
      if (prop === 'searchFields') {
        const searchFields = payload[prop].reduce((acc, next) => {
          acc[next.searchType] = next
          return acc
        }, {})

        result = setIn(
          result,
          'meta.fastProduct.searchFields',
          fromJS(searchFields)
        )
      } else {
        const data =
          prop === 'statusOptions' && Array.isArray(payload[prop])
            ? payload[prop].reduce((acc, next) => {
                acc = acc.concat({
                  ...next,
                  dataId: next.dataId ? next.dataId : 'A'
                })
                return acc
              }, [])
            : payload[prop]
        result = setIn(result, `meta.fastProduct.${prop}`, fromJS(data))
      }
    }

    return result
  },
  [CONSTANTS.GET_FAST_PRODUCT_DEFAULTS.SUCCESS]: (state, { payload }) => {
    let result = state
    result = handleFastProductData(result, payload)
    return result
  },
  [CONSTANTS.FAST_PRODUCT_PROPERTY_CHANGE.SUCCESS]: (
    state,
    { payload: { record } }
  ) => {
    let result = state
    result = handleFastProductData(
      result,
      record,
      ['warnCommas', 'patterns', 'patternType'],
      ['dataId'],
      false
    )
    return result
  },
  [CONSTANTS.FAST_PRODUCT_PROPERTY_CHANGE.FAILURE]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state
    const prevValue = getIn(
      result,
      `fields.fastProduct.${propertyName}.prevValue`
    )

    if (propertyName === 'statusType' && !prevValue) {
      return result
    }

    result = setIn(
      result,
      `fields.fastProduct.${propertyName}.value`,
      prevValue
    )

    result = setIn(result, `values.fastProduct.${propertyName}`, prevValue)

    return result
  },
  [CONSTANTS.CANCEL_FAST_PRODUCT_ENTRY.SUCCESS]: (state, action) => {
    let result = state

    result = deleteIn(result, 'fields.fastProduct')
    result = deleteIn(result, 'values.fastProduct')
    result = deleteIn(result, 'meta.fastProduct')

    return result
  },
  [DDICONSTANTS.OPEN_SCREEN.SUCCESS]: (state, action) => {
    let result = state

    result = setIn(result, 'additionalDataMap', fromJS({}))

    if (getIn(result, 'guid')) {
      let gridLayouts = getIn(result, 'meta.gridLayouts')
      gridLayouts = gridLayouts?.toJS ? gridLayouts.toJS() : []

      gridLayouts = gridLayouts.reduce((acc, next) => {
        if (next?.layoutKey === 'SalesOrderEntryLineItems') {
          acc = acc.concat({
            ...next,
            default: {
              bands: next?.default?.bands?.reduce((a, n) => {
                if (n?.key === 'LineItem') {
                  a = a.concat({
                    ...n,
                    columns: n.columns.reduce((x, y, i) => {
                      if (y.key === 'quantity') {
                        x = x.concat(y)
                        x = x.concat({
                          key: 'bins',
                          value: {
                            position: is.number(y?.value?.position)
                              ? y?.value?.position + 0.5
                              : i + 0.5,
                            fixed: false
                          }
                        })
                      } else {
                        x = x.concat(y)
                      }
                      return x
                    }, [])
                  })
                } else {
                  a = a.concat(n)
                }
                return a
              }, [])
            }
          })
        } else {
          acc = acc.concat(next)
        }

        return acc
      }, [])

      result = setIn(result, 'meta.gridLayouts', fromJS(gridLayouts))
    }
    return result
  },
  [CONSTANTS.LAUNCH_MANUAL_SHIP_TO.SUCCESS]: (state, { payload }) => {
    let result = state

    if (payload && Object.keys(payload).length) {
      for (const prop in payload) {
        result = setIn(
          result,
          `fields.manualShipTo.${prop}.value`,
          payload[prop]
        )
      }
    }

    result = setIn(result, 'values.manualShipTo', fromJS({ ...payload }))

    return result
  },
  [CONSTANTS.SET_FIELD_ERROR_MESSAGE]: (
    state,
    { payload: { propertyName, errorMessage } }
  ) => {
    let result = state

    /* used in Fast Product routines */
    result = setIn(result, `fields.${propertyName}.errorMessage`, errorMessage)
    return result
  },
  [CONSTANTS.CLEAR_INVALID_FAST_PRODUCT_ID]: (state, action) => {
    let result = state

    result = setIn(result, 'fields.fastProduct.dataId.value', '')
    result = setIn(result, 'fields.fastProduct.dataId.errorMessage', '')
    result = setIn(result, 'values.fastProduct.dataId', '')

    return result
  },
  [CONSTANTS.SAVE_FAST_PRODUCT.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingFastProductData', true)
    return result
  },
  [CONSTANTS.SAVE_FAST_PRODUCT.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingFastProductData', false)
    return result
  },
  [CONSTANTS.SAVE_FAST_PRODUCT.SUCCESS]: (state, action) => {
    let result = state

    result = setIn(result, 'isPostingFastProductData', false)
    result = deleteIn(result, 'fields.fastProduct')
    result = deleteIn(result, 'values.fastProduct')
    result = deleteIn(result, 'meta.fastProduct')

    return result
  },
  [CONSTANTS.GET_FAST_PRODUCT_COPY_FROM_META.SUCCESS]: (
    state,
    { payload: { searchFields } }
  ) => {
    let result = state

    if (searchFields && searchFields.length) {
      result = setIn(
        result,
        'meta.fastProduct.searchFields.FromId',
        fromJS(searchFields[0])
      )
    }

    result = setIn(result, 'values.fastProduct.copyFromInterfaceOpen', true)
    return result
  },
  [CONSTANTS.GET_FAST_PRODUCT_COPY_FROM_META.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'values.fastProduct.copyFromInterfaceOpen', false)
    return result
  },
  [CONSTANTS.GET_FAST_PRODUCT_COPY_FROM_DESCRIPTIONS.SUCCESS]: (
    state,
    { payload: { displayUOM, priceUOM } }
  ) => {
    let result = state
    // //
    result = setIn(result, 'values.fastProduct.copyFromDisplayUOM', displayUOM)
    result = setIn(result, 'values.fastProduct.copyFromPriceUOM', priceUOM)
    return result
  },
  [CONSTANTS.FAST_PRODUCT_COPY_FROM_EXECUTE.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingFastProductData', true)
    return result
  },
  [CONSTANTS.FAST_PRODUCT_COPY_FROM_EXECUTE.SUCCESS]: (state, { payload }) => {
    let result = state

    /*
      only accept fields that have values, are booleans --- SVE 11/15/2019
      statusType requires a propertyChange -- SVE 12/3/2019
    */

    const copyData = {}
    const isBoolean = ['isSerialNumberRequired', 'isTaxable', 'warnCommas']

    for (const prop in payload) {
      if (
        (payload[prop] && prop !== 'statusType' && prop !== 'statusTypeCopy') ||
        isBoolean.includes(prop)
      ) {
        copyData[prop] = payload[prop]
      }
    }
    result = handleFastProductData(result, copyData)

    /* clear the Copy From fields */
    result = setIn(result, 'fields.fastProduct.fromId.value', '')
    result = setIn(result, 'fields.fastProduct.fromDescription.value', '')
    result = setIn(result, 'values.fastProduct.fromId', '')
    result = setIn(result, 'values.fastProduct.fromDescription', '')

    result = setIn(result, 'values.fastProduct.copyFromDisplayUOM', '')
    result = setIn(result, 'values.fastProduct.copyFromPriceUOM', '')

    result = setIn(result, 'values.fastProduct.copyFromInterfaceOpen', false)
    result = setIn(result, 'isPostingFastProductData', false)
    return result
  },
  [CONSTANTS.FAST_PRODUCT_COPY_FROM_CANCEL.SUCCESS]: (state, action) => {
    let result = state

    result = setIn(result, 'fields.fastProduct.fromId.value', '')
    result = setIn(result, 'values.fastProduct.fromId', '')
    result = setIn(result, 'fields.fastProduct.fromDescription.value', '')
    result = setIn(result, 'values.fastProduct.fromDescription', '')

    result = setIn(result, 'values.fastProduct.copyFromDisplayUOM', '')
    result = setIn(result, 'values.fastProduct.copyFromPriceUOM', '')

    result = setIn(result, 'values.fastProduct.copyFromInterfaceOpen', false)
    return result
  },
  [CONSTANTS.LAUNCH_FAST_CUSTOMER.SUCCESS]: (state, { payload }) => {
    let result = state

    let rowData = getIn(result, 'values.fastCustomer') || new Map()

    if (payload && Object.keys(payload).length) {
      for (const prop in payload) {
        const val = payload[prop] != null ? payload[prop] : ''
        result = setIn(result, `fields.fastCustomer.${prop}.value`, val)
        // should check first if field has error before - LL 11/19/19
        result = setIn(result, `fields.fastCustomer.${prop}.errorMessage`, '')
      }
    }

    rowData = rowData.mergeDeep(fromJS({ ...payload }))
    result = setIn(result, 'values.fastCustomer', rowData)

    if (
      payload &&
      payload.validationMessages &&
      is.array(payload.validationMessages) &&
      payload.validationMessages.length
    ) {
      payload.validationMessages.forEach(x => {
        const prop = toCamelCase(x.key)
        result = setIn(
          result,
          `fields.fastCustomer.${prop}.errorMessage`,
          x.value
        )
      })
    }

    return result
  },
  [CONSTANTS.INITIALIZE_DEFAULT_TO_QUOTE]: (state, { payload }) => {
    let result = state

    result = setIn(result, 'fields.quote.value', payload.value)
    result = setIn(result, 'values.quote', payload.value)

    return result
  },
  [CONSTANTS.PRODUCT_SUBSTITUTE.SUCCESS]: (
    state,
    { payload: { record, validationMessages } }
  ) => {
    let result = state

    /* important that this gets called first here */
    result = updateRecordFromAPI(result, record, validationMessages)
    const oldLineItems = getIn(result, 'fields.lineItems.rowData')
    if (
      record &&
      record.detail &&
      record.detail.lineItems &&
      record.detail.lineItems.length
    ) {
      const lineItems = record.detail.lineItems.reduce((acc, next) => {
        const oldLine = oldLineItems.filter(
          x => x.get('lineNumber') === next.lineNumber
        )
        const lineItem = {
          ...next,
          rowId: oldLine && oldLine.first().get('rowId')
        }
        const { ...rest } = lineItem
        acc = acc.concat(rest)
        return acc
      }, [])

      lineItems.forEach(x => setAdditionalData(result, x))

      result = setIn(result, 'fields.lineItems.rowData', fromJS(lineItems))
      result = setIn(result, 'values.lineItems', fromJS(lineItems))
    }

    return result
  },
  [CONSTANTS.CHANGE_ITEM_GROUPS.SUCCESS]: (state, { payload: { groups } }) => {
    let result = state

    result = updateGroups(result, groups)
    result = setIn(result, 'fields.groups.itemGroups.isPending', false)

    return result
  },
  [CONSTANTS.LAUNCH_ITEM_GROUPS_MODAL.SUCCESS]: (state, { payload }) => {
    let result = state
    // have to set emptyRow here for itemGroups til gridReducer is fixed to do it automatically
    // - LL 11/26/19

    const blankRow = {
      rowId: 'blankrow',
      lineNumber: 1,
      dataId: null,
      lineItems: [],
      pricePer: 0,
      quantity: 0,
      priceExtension: 0,
      costExtension: 0
    }

    result = setIn(
      result,
      'fields.groups.itemGroups.emptyRow',
      fromJS(blankRow)
    )

    if (payload && payload.availableLineItems) {
      result = setIn(
        result,
        'fields.groups.availableLineItems.rowData',
        fromJS(payload.availableLineItems)
      )
      result = setIn(result, 'fields.groups.availableLineItems.grid', true)
    }

    if (payload && payload.itemGroups) {
      const groups = payload.itemGroups.reduce((acc, next) => {
        acc = acc.concat([
          {
            rowId: next.dataId,
            ...next
          }
        ])
        return acc
      }, [])
      result = setIn(result, 'fields.groups.itemGroups.rowData', fromJS(groups))
      result = setIn(result, 'fields.groups.itemGroups.grid', true)
    }
    result = setIn(result, 'fields.groups.itemGroups.isPending', false)

    return result
  },
  [CONSTANTS.CANCEL_ITEM_GROUPS.SUCCESS]: state => {
    let result = state

    // clear availableLineItems and itemGroups
    result = setIn(
      result,
      'fields.groups.availableLineItems.rowData',
      fromJS([])
    )
    result = setIn(result, 'fields.groups.itemGroups.rowData', fromJS([]))

    result = setIn(result, 'fields.groups.itemGroups.selectedRowIndex', null)

    return result
  },
  [CONSTANTS.OPEN_PREVIOUS_ORDER]: (state, { payload: { dataId } }) => {
    let result = state
    const previousOrders = getIn(result, 'previousOrders')
    result = setIn(result, 'previousOrders', previousOrders.remove(dataId))

    return result
  },
  [CONSTANTS.CLEAR_LINE_ITEMS_GRID]: state => {
    let result = state

    result = setIn(
      result,
      'fields.lineItems.rowData',
      fromJS([
        {
          rowId: 'blankrow',
          lineNumber: 1,
          dataId: null,
          description: '',
          quantityOrdered: 0,
          uomId: 'EA',
          netPrice: 0,
          comments: [],
          data: [],
          itemGroupId: null,
          itemGroupDescription: ''
        }
      ])
    )

    result = setIn(
      result,
      'values.lineItems',
      fromJS([
        {
          rowId: 'blankrow',
          lineNumber: 1,
          dataId: null,
          description: '',
          quantityOrdered: 0,
          uomId: 'EA',
          netPrice: 0,
          comments: [],
          data: [],
          itemGroupId: null,
          itemGroupDescription: ''
        }
      ])
    )

    return result
  },
  [CONSTANTS.SAVE_ITEM_GROUPS.SUCCESS]: (
    state,
    { payload: { record, validationMessages } }
  ) => {
    let result = state

    result = updateRecordFromAPI(result, record, validationMessages)

    // reset selectedRow
    result = setIn(result, 'fields.groups.itemGroups.selectedRowIndex', null)
    result = setIn(
      result,
      'fields.groups.availableLineItems.selectedRowIndex',
      null
    )

    return result
  },
  [CONSTANTS.ITEM_GROUPS_PROPERTY_CHANGE.SUCCESS]: (
    state,
    { payload: { groups } }
  ) => {
    let result = state

    if (groups) {
      result = updateGroups(state, groups)
    }

    return result
  },

  [CONSTANTS.DELETE_ITEM_GROUP.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.groups.itemGroups.selectedRowIndex', null)
    result = setIn(
      result,
      'fields.groups.availableLineItems.selectedRowIndex',
      null
    )
    return result
  },
  [CONSTANTS.LINE_ITEMS_BULK_ADD.SUCCESS]: (
    state,
    { payload: { record, validationMessages = [] } }
  ) => {
    let result = state
    result = updateRecordFromAPI(result, record, validationMessages)
    result = setIn(result, 'hasRecord', true)
    result = setIn(result, 'isEditing', true)
    return result
  },
  [CONSTANTS.SET_SELECTED_AVAILABLE_PRODUCT]: (
    state,
    { payload: { selectedLineNumbers } }
  ) => {
    let result = state

    if (selectedLineNumbers && is.array(selectedLineNumbers)) {
      result = setIn(
        result,
        'fields.groups.availableLineItems.selectedLineNumbers',
        fromJS(selectedLineNumbers)
      )
    }
    return result
  },
  [CONSTANTS.INITIALIZE_PAYMENT.SUCCESS]: (
    state,
    { payload: { record, validationMessages, paymentTransactionToken } }
  ) => {
    let result = state

    result = updateRecordFromAPI(result, record, validationMessages)

    result = setIn(result, 'paymentAttemptSuccess', true)
    result = setIn(result, 'isPosting', false)

    if (paymentTransactionToken) {
      result = setIn(
        result,
        'paymentTransactionToken',
        fromJS(paymentTransactionToken)
      )
    }

    if (getIn(result, 'fields.checkoutContact')) {
      result = setIn(result, 'fields.checkoutContact.value', '')
      result = setIn(result, 'fields.checkoutContact.description', '')
      result = setIn(result, 'values.checkoutContact', '')
      result = setIn(result, 'values.checkoutContactDescription', '')
    }

    if (getIn(result, 'values.vaultCards')) {
      result = deleteIn(result, 'values.vaultCards')
    }

    if (getIn(result, 'cardTerminalStatus')) {
      result = deleteIn(result, 'cardTerminalStatus')
    }

    result = resetPaymentModalFlags(result)

    if (record?.shipToAddress) {
      /* API sends back header.shipToAddress as null sometimes even though it comes in the root */
      result = setIn(
        result,
        'values.shipToAddress',
        fromJS(record.shipToAddress)
      )
    }

    return result
  },
  [CONSTANTS.INITIALIZE_PAYMENT.TRY]: (
    state,
    { payload: { token, overpaymentResponse, isManualIdEntry } }
  ) => {
    let result = state

    result = setIn(result, 'paymentAttemptSuccess', false)
    result = setIn(result, 'isPosting', true)

    if (overpaymentResponse) {
      result = setIn(result, 'overpaymentResponsePosting', true)
    }

    if (isManualIdEntry) {
      result = setIn(result, 'manualIdPosting', true)
    }

    if (token) {
      result = setIn(
        result,
        'paymentTransactionToken',
        fromJS({ token, success: false })
      )
    }

    return result
  },
  [CONSTANTS.INITIALIZE_PAYMENT.FAILURE]: (state, action) => {
    let result = state

    /*
      flag the payment as failed so the value does not revert in the
      payment component's internal state -- SVE 1/16/20
    */
    result = setIn(result, 'isPosting', false)
    result = setIn(result, 'paymentAttemptSuccess', false)

    result = resetPaymentModalFlags(result)

    return result
  },
  [CONSTANTS.GET_RETURN_MODAL_DATA]: (state, { payload: { data, type } }) => {
    let result = state
    if (
      type === 'invoicehistoryall' &&
      data.invoiceHistory &&
      data.lineNumber
    ) {
      const lineItems = getIn(result, 'fields.lineItems.rowData')
      const rowIndex = lineItems.findIndex(
        x => x.get('lineNumber') === data.lineNumber
      )
      const rowId =
        lineItems && lineItems.get && lineItems.get(rowIndex)
          ? lineItems.get(rowIndex).get('rowId')
          : null

      if (rowId) {
        let additionalDataMap = getIn(state, 'additionalDataMap') || fromJS({})
        additionalDataMap = setIn(
          additionalDataMap,
          `${rowId}.invoiceHistory`,
          fromJS(data.invoiceHistory)
        )
        result = setIn(result, 'additionalDataMap', additionalDataMap)
      }
    }

    return result
  },
  [CONSTANTS.CLEAR_RETURN_MODAL_DATA]: (state, { payload: { lineNumber } }) => {
    let result = state

    const lineItems = getIn(result, 'fields.lineItems.rowData')
    const rowIndex = lineItems.findIndex(
      x => x.get('lineNumber') === lineNumber
    )
    const rowId =
      lineItems && lineItems.get && lineItems.get(rowIndex)
        ? lineItems.get(rowIndex).get('rowId')
        : null

    if (rowId) {
      let additionalDataMap = getIn(state, 'additionalDataMap') || fromJS({})
      additionalDataMap = deleteIn(additionalDataMap, `${rowId}.invoiceHistory`)
      result = setIn(result, 'additionalDataMap', additionalDataMap)
    }

    /* remove temp values */
    result = deleteIn(result, 'values.selectedInvoiceHistory')

    return result
  },
  [CONSTANTS.STORE_RETURN_MODAL_DATA]: (
    state,
    { payload: { lineNumber, selectedInvoiceHistory } }
  ) => {
    let result = state
    /* need to save in state for disabled button in modal */
    result = setIn(
      result,
      'values.selectedInvoiceHistory',
      fromJS(selectedInvoiceHistory)
    )
    return result
  },
  [CONSTANTS.VOID_PAYMENT.SUCCESS]: (
    state,
    { payload: { record, validationMessages } }
  ) => {
    let result = state

    if (record) {
      result = updateRecordFromAPI(result, record, validationMessages)
    }

    return result
  },
  [CONSTANTS.DEPOSIT_INVOICE_ENTRY.TRY]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.DEPOSIT_INVOICE_ENTRY.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.DEPOSIT_INVOICE_ENTRY.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.DEPOSIT_INVOICE_ENTRY.SUCCESS]: (
    state,
    { payload: { record, validationMessages } }
  ) => {
    let result = state

    if (record) {
      result = updateRecordFromAPI(result, record, validationMessages)
    }

    result = setIn(result, 'isPosting', false)
    return result
  },

  [CONSTANTS.CLEAR_PAYMENT_TRANSACTION_DATA]: (state, action) => {
    let result = state

    result = setIn(result, 'isPosting', false)
    result = deleteIn(result, 'paymentTransactionToken')
    result = deleteIn(result, 'paymentAttemptSuccess')
    result = deleteIn(result, 'values.vaultCards')
    result = deleteIn(result, 'cardTerminalStatus')
    result = deleteIn(result, 'overpaymentResponsePosting')
    result = deleteIn(result, 'manualIdPosting')
    result = deleteIn(result, 'cardProcessorInitialized')

    result = setIn(result, 'fields.checkoutContact.value', '')
    result = setIn(result, 'fields.checkoutContact.description', '')
    result = setIn(result, 'values.checkoutContact', '')
    result = setIn(result, 'values.checkoutContactDescription', '')
    return result
  },
  [CONSTANTS.SET_CONTACT_REFERENCE_FIELD]: (
    state,
    { payload: { propertyName } }
  ) => {
    let result = state

    result = setIn(result, 'contactReferenceField', propertyName)
    return result
  },
  [CONSTANTS.GET_VAULT_CARDS.TRY]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.GET_VAULT_CARDS.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.GET_VAULT_CARDS.SUCCESS]: (state, { payload: { vaultCards } }) => {
    let result = state

    result = setIn(result, 'values.vaultCards', fromJS(vaultCards))
    result = setIn(result, 'isPosting', false)

    return result
  },
  [CONSTANTS.REMOVE_VAULT_CARD.SUCCESS]: (
    state,
    { payload: { vaultCards } }
  ) => {
    let result = state

    result = setIn(result, 'values.vaultCards', fromJS(vaultCards))

    return result
  },
  [CONSTANTS.CLEAR_FAST_CONTACT]: state => {
    let result = state

    result = deleteIn(result, 'contact')

    return result
  },
  [CONSTANTS.SELECT_LINKED_REFUND.SUCCESS]: (state, { payload }) => {
    let result = state

    if (payload && Object.keys(payload).length) {
      for (const prop in payload) {
        result = setIn(result, `values.${prop}`, fromJS(payload[prop]))
      }
    }

    return result
  },
  [CONSTANTS.LINKED_REFUND_RESPONSE]: (state, { payload: { data } }) => {
    let result = state
    const properties = ['approvalStatus', 'transactionID']

    if (data && Object.keys(data).length) {
      for (const prop in data) {
        if (properties.includes(prop)) {
          result = setIn(result, `values.${prop}`, data[prop])
        }
      }
    }

    return result
  },
  [CONSTANTS.SET_DEFAULT_PAYMENT_CONTACT]: (
    state,
    { payload: { value, description } }
  ) => {
    let result = state

    result = setIn(result, 'fields.checkoutContact.value', value)
    result = setIn(result, 'fields.checkoutContact.description', description)
    result = setIn(result, 'values.checkoutContact', value)
    result = setIn(result, 'values.checkoutContactDescription', description)

    return result
  },
  [CONSTANTS.READ_CARD_TERMINAL_STATUS.FAILURE]: (state, { payload }) => {
    let result = state

    result = setIn(
      result,
      'cardTerminalStatus',
      fromJS({
        offline: true,
        message: payload?.message || 'Terminal not found'
      })
    )

    return result
  },
  [CONSTANTS.READ_CARD_TERMINAL_STATUS.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'cardTerminalStatus', fromJS(payload))
    return result
  },
  [CONSTANTS.CANCEL_CARD_READER_PAYMENT.SUCCESS]: (state, action) => {
    let result = state
    result = deleteIn(result, 'cardTerminalStatus')
    return result
  },
  [CONSTANTS.CARD_PROCESSOR_INITIALIZED]: (
    state,
    { payload: { initialized } }
  ) => {
    let result = state
    result = setIn(result, 'cardProcessorInitialized', initialized)
    return result
  },
  [CONSTANTS.REVERT_DATA_ID]: (state, { payload: { dataId } }) => {
    let result = state
    result = setIn(result, 'fields.dataId.value', dataId)
    result = setIn(result, 'values.dataId', dataId)

    return result
  },
  [CONSTANTS.REMOVE_ADDITIONAL_DATA_MAP_ROW]: (
    state,
    { payload: { rowId } }
  ) => {
    let result = state
    if (rowId) {
      let additionalDataMap = getIn(state, 'additionalDataMap') || fromJS({})
      additionalDataMap = deleteIn(additionalDataMap, rowId)
      result = setIn(result, 'additionalDataMap', additionalDataMap)
    }

    return result
  },
  [CONSTANTS.SAVE_REPAIR_ITEM_DATA.TRY]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.SAVE_REPAIR_ITEM_DATA.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.SAVE_REPAIR_ITEM_DATA.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.SAVE_REPAIR_ITEM_DATA.SUCCESS]: (
    state,
    { payload: { record, validationMessages } }
  ) => {
    let result = state

    if (record) {
      result = updateRecordFromAPI(result, record, validationMessages)
    }

    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.LOCK_PRODUCT_NOTE.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'isEditingProductNotes', true)
    return result
  },
  [CONSTANTS.UNLOCK_PRODUCT_NOTE.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'isEditingProductNotes', false)
    return result
  },
  [CONSTANTS.SAVE_PRODUCT_NOTE.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'isEditingProductNotes', false)
    return result
  },
  [CONSTANTS.SAVE_PART_NUMBER.SUCCESS]: (state, { payload }) => {
    let result = state
    result = updateCustomerPartNumber(result, payload)
    result = deleteIn(result, 'fields.selectedCustomerPartNumber')
    result = deleteIn(result, 'values.selectedCustomerPartNumber')

    return result
  },
  [CONSTANTS.DELETE_PART_NUMBER.SUCCESS]: (state, { payload }) => {
    let result = state

    result = updateCustomerPartNumber(result, payload)

    // remove selected part number
    result = deleteIn(result, 'fields.selectedCustomerPartNumber')
    result = deleteIn(result, 'values.selectedCustomerPartNumber')

    return result
  },
  [CONSTANTS.CANCEL_EDIT_PART_NUMBER.SUCCESS]: (state, { payload }) => {
    let result = state

    // remove selected part number
    result = deleteIn(result, 'fields.selectedCustomerPartNumber')
    result = deleteIn(result, 'values.selectedCustomerPartNumber')

    return result
  },
  [CONSTANTS.INITIALIZE_MULTI_SELECT.REQUEST]: (state, { payload }) => {
    let result = state
    /* lineItem details tab needs this flag */
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.INITIALIZE_MULTI_SELECT.SUCCESS]: (state, { payload }) => {
    let result = state

    if (payload) {
      result = updateRecordFromAPI(result, payload, payload?.validationMessages)
    }

    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.INITIALIZE_MULTI_SELECT.FAILURE]: (state, { payload }) => {
    let result = state
    /* lineItem details tab needs this flag */
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.SET_SELECTED_MULTI_RETAIN_TAXABLE]: (state, { payload }) => {
    let result = state

    if (payload?.lineNumbers) {
      result = setIn(
        result,
        'ui.selectedMultiSelectRetainTaxable',
        fromJS(payload.lineNumbers)
      )
    }

    return result
  },
  [CONSTANTS.SET_PROMISE_DATE.SUCCESS]: (state, { payload }) => {
    let result = state

    if (payload) {
      result = updateRecordFromAPI(result, payload, payload?.validationMessages)
    }

    result = deleteIn(result, 'fields.multiSelectPromisedDate')
    result = deleteIn(result, 'values.multiSelectPromisedDate')

    return result
  },
  [CONSTANTS.SET_PROMISE_DATE.FAILURE]: (state, action) => {
    let result = state

    result = deleteIn(result, 'fields.multiSelectPromisedDate')
    result = deleteIn(result, 'values.multiSelectPromisedDate')

    return result
  },
  [CONSTANTS.HANDLE_MULTIPLE_LINE_ITEM_UPDATES.SUCESS]: (
    state,
    { payload }
  ) => {
    let result = state
    const validationMessages = payload?.validationMessages
      ? payload.validationMessages
      : []
    result = updateRecordFromAPI(result, payload, validationMessages)
    return result
  },

  [CONSTANTS.HANDLE_PROCUREMENT_GRID_UPDATE.TRY]: (
    state,
    {
      payload: {
        field,
        value,
        rowIndex,
        gridName,
        isRowDeletionHandler = false
      }
    }
  ) => {
    let result = state

    if (isRowDeletionHandler) {
      return result
    }

    const selectedRowIndex = getIn(result, 'fields.lineItems.selectedRowIndex')
    let lineItems = getIn(result, 'fields.lineItems.rowData')
    const rowKey = gridName === 'linkedTransfers' ? 'fromWarehouseId' : 'dataId'
    const row = lineItems.get(selectedRowIndex) || fromJS({ dataId: null })

    if (!row || !row.get('dataId')) {
      return result
    }

    let grid = row.get(gridName) || fromJS([{ [rowKey]: null }])

    if (field === rowKey) {
      const keyInExistingRow = grid.findIndex(x => x.get(rowKey) === value)
      if (keyInExistingRow === -1) {
        grid = grid.update(rowIndex, data => data.set(field, value))
      } else {
        grid = grid.update(rowIndex, data => data.set(field, ''))
      }
    } else {
      grid = grid.update(rowIndex, data => data.set(field, value))
    }

    lineItems = lineItems.update(selectedRowIndex, data =>
      data.set(gridName, grid)
    )

    result = setIn(result, 'fields.lineItems.rowData', lineItems)

    return result
  },
  [CONSTANTS.HANDLE_PROCUREMENT_GRID_UPDATE.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.HANDLE_PROCUREMENT_GRID_UPDATE.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.HANDLE_PROCUREMENT_GRID_UPDATE.SUCCESS]: (state, action) => {
    let result = state
    const {
      payload: { record, validationMessages = [] }
    } = action

    result = updateRecordFromAPI(result, record, validationMessages)

    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.ADD_PROCUREMENT_GRID_ROW]: (
    state,
    { payload: { gridName, rowKey, emptyGridRow = [] } }
  ) => {
    let result = state
    const selectedRowIndex = getIn(result, 'fields.lineItems.selectedRowIndex')
    let lineItems = getIn(result, 'fields.lineItems.rowData')
    const row = lineItems.get(selectedRowIndex) || fromJS({ dataId: null })

    if (!row || !row.get('dataId')) {
      return result
    }

    const grid = row.get(gridName) || fromJS([])

    if (!grid.size || (grid.size && grid.get(grid.size - 1).get(rowKey))) {
      lineItems = lineItems.update(selectedRowIndex, data =>
        data.set(gridName, grid.push(fromJS(emptyGridRow)))
      )

      result = setIn(result, 'fields.lineItems.rowData', lineItems)
    }

    return result
  },
  [CONSTANTS.CLEAN_UP_PROCUREMENT_GRID_ROW]: (
    state,
    { payload: { gridName, selectedRowIndex, rowData, rowKey } }
  ) => {
    let result = state
    let lineItems = getIn(result, 'fields.lineItems.rowData')

    if (!lineItems) {
      return result
    }

    const row = lineItems.get(selectedRowIndex)

    if (!row || !row.get('dataId')) {
      return result
    }

    let grid = row.get(gridName) || fromJS([])
    grid = grid.reduce((acc, next) => {
      if (next.get(rowKey)) {
        acc = acc.push(next)
      }
      return acc
    }, emptyList)

    lineItems = lineItems.update(selectedRowIndex, data =>
      data.set(gridName, grid)
    )

    result = setIn(result, 'fields.lineItems.rowData', lineItems)

    return result
  },
  [CONSTANTS.FLAG_NEWLY_ADDED_LINE_NUMBER]: (
    state,
    { payload: { lineNumber } }
  ) => {
    let result = state

    result = setIn(result, 'fields.lineItems.lastLineNumberAdded', lineNumber)
    return result
  },
  [CONSTANTS.LAUNCH_RECALCULATE_PRICES_MODAL.SUCCESS]: (state, { payload }) => {
    let result = state

    if (payload.recalculatePrices) {
      const record = payload.recalculatePrices

      for (const prop in record) {
        result = setIn(result, `fields.${prop}.value`, fromJS(record[prop]))
        result = setIn(result, `values.${prop}`, fromJS(record[prop]))

        if (prop === 'rollups') {
          // initialize grid and selected sequence
          // first is always selected on launch
          result = setIn(result, `fields.${prop}.rowData`, fromJS(record[prop]))
          result = setIn(result, `fields.${prop}.grid`, true)
          result = setIn(result, `fields.${prop}.selectedSequenceNumber`, 1)
        }
      }
    }

    return result
  },
  [CONSTANTS.CLOSE_RECALCULATE_PRICES.SUCCESS]: state => {
    let result = state

    // rollups can change, can't merge

    result = deleteIn(result, 'fields.rollups')
    result = deleteIn(result, 'values.rollups')

    return result
  },
  [CONSTANTS.CHANGE_RECALCULATE_PRICES.SUCCESS]: (state, { payload }) => {
    let result = state

    result = updateRecordFromAPI(result, payload, [])

    if (!payload.rollups.length) {
      result = setIn(result, 'fields.rollups.selectedSequenceNumber', null)
    }
    return result
  },
  [CONSTANTS.SET_SELECTED_SEQUENCE_NUMBER]: (state, { payload }) => {
    let result = state
    const { sequenceNumber = 1 } = payload

    result = setIn(
      result,
      'fields.rollups.selectedSequenceNumber',
      sequenceNumber
    )
    return result
  },
  [CONSTANTS.RECALCULATE_PRICES.SUCCESS]: (state, { payload }) => {
    let result = state

    result = updateRecordFromAPI(result, payload.record, [])

    return result
  },
  [CONSTANTS.CREDIT_INVOICE.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.CREDIT_INVOICE.SUCCESS]: (state, { payload }) => {
    let result = state
    result = initMiscChargesGrid(result)
    /* temp for MiscCharge editableGrid */

    result = setIn(result, 'isPosting', false)
    result = updateRecordFromAPI(result, payload, [])

    // set hasRecord/isEditing to true if the record has been locked by the API
    if (payload.isLocked) {
      result = setIn(result, 'hasRecord', true)
      result = setIn(result, 'isEditing', true)
    }

    if (payload?.shipToAddress) {
      /* API sends back header.shipToAddress as null sometimes even though it comes in the root */
      result = setIn(
        result,
        'values.shipToAddress',
        fromJS(payload.shipToAddress)
      )
    }

    return result
  },
  [CONSTANTS.CREDIT_INVOICE.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
      result,
      action
    )
    result = setIn(result, 'additionalDataMap', fromJS({}))

    return result
  },
  [CONSTANTS.FLAG_ACTIVE_GRID_LAYOUT]: (
    state,
    { payload: { gridName, action = 'add' } }
  ) => {
    let result = state

    const activeGridLayouts = getIn(result, 'activeGridLayouts') || Set()
    if (!activeGridLayouts.size) {
      result = setIn(result, 'activeGridLayouts', activeGridLayouts)
    }

    if (action === 'add') {
      result = setIn(
        result,
        'activeGridLayouts',
        activeGridLayouts.add(gridName)
      )
    } else {
      result = setIn(
        result,
        'activeGridLayouts',
        activeGridLayouts.delete(gridName)
      )
    }

    return result
  },
  [CONSTANTS.CHANGE_FAST_PRODUCT_VENDOR.SUCCESS]: (
    state,
    { payload: { record } }
  ) => {
    let result = state
    result = setIn(result, 'meta.fastProduct.canChangeVendor', true)
    result = handleFastProductData(result, record)
    return result
  },
  [CONSTANTS.GET_WAREHOUSE_INVENTORY.REQUEST]: state => {
    let result = state
    result = setIn(result, 'isFetchingAdditionalData', true)
    return result
  },
  [CONSTANTS.GET_WAREHOUSE_INVENTORY.SUCCESS]: (state, { payload }) => {
    let result = state
    result = onFetchAdditionalDataSuccess(result, payload, true)
    return result
  },
  [CONSTANTS.GET_WAREHOUSE_INVENTORY.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isFetchingAdditionalData', false)
    return result
  },
  [CONSTANTS.CALCULATE_CUSTOM_CUT.SUCCESS]: (state, action) => {
    const {
      payload: { record, validationMessages = [] }
    } = action
    let result = state

    result = updateRecordFromAPI(result, record, validationMessages)
    result = deleteIn(result, 'fields.customCut')
    result = deleteIn(result, 'values.customCut')

    return result
  },
  [CONSTANTS.GET_SOE_SALESMEN_COMMISSIONS
    .SUCCESS]: handleSalesmanCommissionData,
  [CHANGE_COMMISSION_PROPERTY.SUCCESS]: handleSalesmanCommissionData,
  [CHANGE_COMMISSION_VALUE.SUCCESS]: (
    state,
    {
      payload: {
        record: { lineItems }
      }
    }
  ) => {
    let result = state
    if (lineItems && Array.isArray(lineItems)) {
      result = setIn(
        result,
        'values.changeInvoice.salesmenCommissions',
        fromJS(lineItems)
      )
    }
    return result
  },
  [CONSTANTS.MODIFY_LINE_ITEM_SERIAL_NUMBER.TRY]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.MODIFY_LINE_ITEM_SERIAL_NUMBER.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.MODIFY_LINE_ITEM_SERIAL_NUMBER.SUCCESS]: (state, { payload }) => {
    let result = state
    const { activeRowId } = payload

    if (!activeRowId) {
      return result
    }

    const serials =
      payload?.record?.detail?.lineItems?.find(x => x.uniqueKey === activeRowId)
        ?.bins?.[0].serialNumbers || []

    let serialNumbers =
      getIn(result, `fields.serialNumbers.${activeRowId}`) || fromJS({})
    serialNumbers = Object.keys(serialNumbers)

    if (serialNumbers?.length) {
      for (let i = 0; i < serialNumbers.length; i++) {
        const value = serials?.[i]?.dataId
        if (serials?.[i]) {
          result = setIn(
            result,
            `fields.serialNumbers.${activeRowId}.${i}.value`,
            value
          )
          result = setIn(
            result,
            `values.serialNumbers.${activeRowId}.${i}`,
            value
          )
        } else {
          result = deleteIn(result, `fields.serialNumbers.${activeRowId}.${i}`)
          result = deleteIn(result, `values.serialNumbers.${activeRowId}.${i}`)
        }
      }
    }

    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.MODIFY_LINE_ITEM_SERIAL_NUMBER.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.DESTROY_SERIAL_NUMBER_FIELDS]: (state, action) => {
    let result = state
    result = deleteIn(result, 'fields.serialNumbers')
    result = deleteIn(result, 'values.serialNumbers')

    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields?.toJS ? editedFields.toJS() : []

    editedFields = editedFields.filter(x => !x?.match(/serialNumbers/))
    result = setIn(result, 'editedFields', Set([...editedFields]))

    return result
  },
  [CONSTANTS.HANDLE_SERIAL_NUMBER_REVIEW_CHANGE.FAILURE]: (
    state,
    { payload }
  ) => {
    let result = state
    const isIncomplete = payload?.data?.isIncomplete || false

    result = setIn(result, 'values.serialNumberIsIncomplete', isIncomplete)

    return result
  },
  [CONSTANTS.DESTROY_VIRTUAL_FIELDS]: (state, { payload: { key } }) => {
    /* this is going to overwrite destroySerialNumberFields above */
    let result = state
    if (!key) {
      return result
    }

    result = deleteIn(result, `fields.${key}`)
    result = deleteIn(result, `values.${key}`)

    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields?.toJS ? editedFields.toJS() : []

    const regex = new RegExp(key, 'g')
    editedFields = editedFields.filter(x => !x?.match(regex))
    result = setIn(result, 'editedFields', Set([...editedFields]))

    return result
  },
  [CONSTANTS.HANDLE_REBATE_INFO_INTERACTION.SUCCESS]: (state, action) => {
    const {
      payload: { record, validationMessages = [] }
    } = action
    let result = state

    result = updateRecordFromAPI(result, record, validationMessages)
    result = deleteIn(result, 'fields.rebateInfo')
    result = deleteIn(result, 'values.rebateInfo')

    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields?.toJS ? editedFields.toJS() : []

    editedFields = editedFields.filter(x => !x?.match(/rebateInfo/))
    result = setIn(result, 'editedFields', Set([...editedFields]))

    return result
  },
  [CONSTANTS.HANDLE_SWAP_COMMITTED_QUANTITIES_INTERACTION.SUCCESS]: (
    state,
    action
  ) => {
    const {
      payload: { record = null, validationMessages = [] }
    } = action
    let result = state

    if (record) {
      result = updateRecordFromAPI(result, record, validationMessages)
    }

    return result
  },
  [DDICONSTANTS.REGISTER_FIELD]: (
    state,
    { payload: { propertyName, rowData } }
  ) => {
    let result = state

    if (
      propertyName &&
      typeof propertyName === 'string' &&
      propertyName.match(/lineItemComponents/) &&
      rowData &&
      Array.isArray(rowData)
    ) {
      result = setIn(result, `fields.${propertyName}.rowData`, fromJS(rowData))
      result = setIn(result, `fields.${propertyName}.isPending`, false)
    }

    if (
      propertyName === 'lineItems' &&
      getIn(result, 'guid') &&
      !getIn(result, 'fields.lineItems.emptyRow')
    ) {
      result = setIn(result, 'fields.lineItems.emptyRow', fromJS(emptyRow))
    }

    if (
      propertyName === 'miscellaneousCharges' &&
      !getIn(result, 'fields.miscellaneousCharges.emptyRow')
    ) {
      result = setIn(
        result,
        'fields.miscellaneousCharges.emptyRow',
        fromJS({
          dataId: null,
          description: '',
          amount: 0,
          taxable: false
        })
      )
    }

    return result
  },
  [CONSTANTS.ADD_COMPONENTS_ROW]: (
    state,
    {
      payload: {
        parentLineNumber,
        rowId,
        componentsGridExpanded = true,
        isExternal = false
      }
    }
  ) => {
    let result = state

    if (isExternal) {
      return result
    }
   
    let lineItems = getIn(result, 'fields.lineItems.rowData') || fromJS([])
    lineItems = lineItems?.toJS ? lineItems.toJS() : []

    const rowIndex = lineItems.findIndex(x => x.rowId === rowId)

    if (
      is.number(rowIndex) &&
      lineItems?.[rowIndex]?.components &&
      Array.isArray(lineItems[rowIndex].components) &&
      lineItems?.[rowIndex]?.components?.every(x => x.dataId)
    ) {
      const formattedComponents = [
        ...lineItems[rowIndex].components,
        {
          rowId: 'blankrow',
          lineNumber: lineItems[rowIndex].components.length + 1,
          dataId: null,
          description: '',
          quantityExtended: null,
          quantity: null,
          parentLineNumber
        }
      ]
      lineItems[rowIndex].components = formattedComponents

      result = setIn(
        result,
        `fields.lineItemComponents.${rowId}.rowData`,
        fromJS(formattedComponents)
      )

      result = setIn(
        result,
        `fields.lineItemComponents.${rowId}.isPending`,
        false
      )

      if (getIn(result, `values.lineItemComponents.${rowId}`)) {
        result = setIn(
          result,
          `values.lineItemComponents.${rowId}`,
          fromJS(formattedComponents)
        )
      }

      result = setIn(result, `fields.lineItems.rowData`, fromJS(lineItems))
      result = setIn(result, `values.lineItems`, fromJS(lineItems))
    } else if (
      is.number(rowIndex) &&
      lineItems?.[rowIndex]?.components === null
    ) {
      const formattedComponents = [
        {
          rowId: 'blankrow',
          lineNumber: 1,
          dataId: null,
          description: '',
          quantityExtended: null,
          quantity: null,
          parentLineNumber,
          uniqueKey: `${parentLineNumber}-1`
        }
      ]
      lineItems[rowIndex].components = formattedComponents

      result = setIn(
        result,
        `fields.lineItemComponents.${rowId}.rowData`,
        fromJS(formattedComponents)
      )

      result = setIn(
        result,
        `fields.lineItemComponents.${rowId}.isPending`,
        false
      )

      if (getIn(result, `values.lineItemComponents.${rowId}`)) {
        result = setIn(
          result,
          `values.lineItemComponents.${rowId}`,
          fromJS(formattedComponents)
        )
      }

      result = setIn(result, `fields.lineItems.rowData`, fromJS(lineItems))
      result = setIn(result, `values.lineItems`, fromJS(lineItems))
    }

    return result
  },
  [CONSTANTS.CLEAR_COMPONENTS_ROW]: (
    state,
    { payload: { parentLineNumber, childLineNumber } }
  ) => {
    let result = state

    let lineItems = getIn(result, 'fields.lineItems.rowData') || fromJS([])
    lineItems = lineItems && lineItems?.toJS ? lineItems.toJS() : []

    const rowIndex = lineItems.findIndex(
      x => x?.lineNumber === parentLineNumber
    )
    const lineItem = lineItems[rowIndex]
    const rowId = lineItem?.rowId
    let components = lineItem?.components

    if (components && Array.isArray(components) && is.number(rowIndex)) {
      components = components.reduce((acc, next) => {
        if (next?.lineNumber === childLineNumber) {
          acc = acc.concat({
            ...next,
            dataId: null,
            description: '',
            quantityExtended: null,
            quantity: null
          })
        } else {
          acc = acc.concat(next)
        }

        return acc
      }, [])

      lineItems[rowIndex].components = components
      result = setIn(result, 'fields.lineItems.rowData', fromJS(lineItems))
      result = setIn(result, 'values.lineItems', fromJS(lineItems))
      result = setIn(
        result,
        `fields.lineItemComponents.${rowId}.rowData`,
        fromJS(components)
      )
    }

    return result
  },
  [CONSTANTS.OPEN_REBATE_COST_TAB.SUCCESS]: (
    state,
    { meta: { form }, payload: { response, lineNumber } }
  ) => {
    const rebateFields = [
      'rebateCost',
      'rebateVendorId',
      'rebateVendorDescription',
      'rebateContractId',
      'rebateCostMethod'
    ]
    let result = state

    let priceChange = getIn(
      result,
      `additionalDataMap.${lineNumber}.priceChange`
    )
    priceChange = priceChange?.toJS ? priceChange.toJS() : priceChange
    priceChange = { ...priceChange, ...response }
    result = setIn(
      result,
      `additionalDataMap.${lineNumber}.priceChange`,
      fromJS(priceChange)
    )

    rebateFields.forEach(field => {
      result = setIn(
        result,
        `values.rebateCostData[${lineNumber - 1}].${field}`,
        priceChange[field]
      )
      result = setIn(
        result,
        `fields.rebateCostData[${lineNumber - 1}].${field}.value`,
        priceChange[field]
      )
    })

    result = setIn(result, 'selectedLineNumber', lineNumber)

    return result
  },
  [CONSTANTS.CREATE_CUSTOM_ASSEMBLY.SUCCESS]: (
    state,
    { payload: { record = null, validationMessages = [] } }
  ) => {
    let result = state

    if (record) {
      result = updateRecordFromAPI(result, record, validationMessages)
    }

    return result
  },
  [CONSTANTS.PRICE_OVERRIDE.REQUEST]: clearReasonCode,
  [CONSTANTS.CANCEL_PRICE_OVERRIDE.TRY]: clearReasonCode,
  ...activityBehaviors,
  ...auditBehaviors,
  ...attachmentsBehaviors,
  ...notesModalBehaviors,
  ...productImportBehaviors
}
