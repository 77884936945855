/* eslint consistent-return: 0 */
import {
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  delay
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import editableGridSagas from 'components/EditableGrid/sagas'
import {
  setField,
  tryChangeFormTab,
  setSelectedRowIndex,
  updateFormTitle
} from 'ddiForm/actions'
import { EXIT, SET_FIELD, DESTROY_FIELD } from 'ddiForm/constants'
import { getFormSelector } from 'ddiForm/utils'
// import { displayValidationErrors } from 'ddiForm/sagas'
import { fromJS } from 'immutable'
import { getIn, is } from 'utils'
import { getRecord } from 'ddiForm/MasterScreen/sagas'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'
import RetainCustomerModalActions from 'pages/SalesOrder/components/RetainCustomerModalActions'
import Dialog from 'modals/Dialog'

import { getErrorMessages } from 'pages/ProductMaster/utils'
import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal, confirm, canceled, removeModal } from 'modals/actions'
import { REMOVE_MODAL, CONFIRMED, CANCELED } from 'modals/constants'
import SalesOrderNumberEntryModal from 'pages/SalesOrder/tabs/Order/components/SalesOrderNumberEntryModal'
import { api } from 'services'
import gridSagas from 'pages/SalesOrder/components/ManualGrid/sagas'
import { additionalAuditsDataFlagListener } from 'components/MasterScreen/Audit/sagas'
import attachmentsSagas from 'components/MasterScreen/Attachments/sagas'
import customerStockMinimumsSagas from 'pages/InvoiceInquiry/sagas/customerStockMinimumsSagas'
import {
  quantityToShipProcessMobile,
  quantityValidationMobile
} from 'mobile/pages/SalesOrder/sagas/quantityHandlingSagas'

import CutLengthModal from '../components/CutLengthModal'

import {
  quantityToShipProcess,
  tabListener,
  saveLayoutForUserAutenticationListener,
  getSalesmenCommissionsListener,
  changeCommissionValueListener,
  commissionIgnoreMinimumsSwitchListener,
  triggerShippingGridBulkChangeListener,
  importAutoQuoteListener
} from './gridSagas'
// import * as masterActions from 'ddiForm/MasterScreen/actions'
// import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
// import SignatureModal from '../components/SignatureModal'
import Signature from '../tabs/Invoicing/components/Signature'
import infoPanelSagas from './infoPanelSagas'
import copyOrderSagas from './copyOrderSagas'
import detailSagas from './detailSagas'
import headerSagas from './headerSagas'
import invoicingSagas from './invoicingSagas'
import fastEntitySagas from './fastEntitySagas'
import activitiesSagas from './activitiesSagas'
import itemGroupSagas, {
  addItemGroupProcess,
  changeItemGroupsGridProcess
} from './itemGroupSagas'
import searchAreaSagas, {
  handleInteractiveModalMessages
} from './searchAreaSagas'
import externalHandlerSagas from './externalHandlerSagas'
import recalculatePricesSagas, {
  changeRecalculatePricesProcess
} from './recalculatePricesSagas'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import {
  extractString,
  isValidQuantityInput,
  calculateResolvedQuantity,
  roundToNextMultiple,
  getGroupNames,
  getInventoryNames,
  getSelectedRowLineNumberFromFormState,
  mapLinePropertyChangeResponse
} from '../utils'
import SpawnedOrdersModal from '../components/SpawnedOrdersModal'
import VoidSalesOrderModal from '../components/VoidSalesOrderModal'
import VoidSalesOrderModalMobile from '../components/VoidSalesOrderModalMobile'
import checkoutSagas, { depositInvoiceEntryProcess } from './checkoutSagas'
import returnOrderSagas, { returnProductProcess } from './returnOrderSagas'
import {
  cancelNewSalesOrderEditProcess,
  cancelSalesOrderEditProcess,
  cancelEditAfterClear
} from './commonSagas'

import notesHandlerSagas from './notesHandlerSagas'
import warrantyTagSagas from './warrantyTagSagas'
import serialNumberSagas, { openSerialNumberEditor } from './serialNumberSagas'
import backorderCommitmentSagas from './backorderCommitmentSagas'
import rebateSagas from './rebateSagas'
import recurringOrderSagas from './recurringOrderSagas'
import customAssemblySagas from './customAssemblySagas'
import shipperHQSagas from './shipperHQSagas'

/* need to get all groupNames for mobile */
const mobileGroupNames = ['header', 'detail', 'final']

export function* closeSalesOrderProcess(form, thunk = {}) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const isEditing = getIn(formState, 'isEditing') || false

  if (isEditing) {
    yield call(
      confirmationModal,
      'All changes will be lost. Continue?',
      'Close?'
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      yield put(
        actions.closeSalesOrder.failure(
          {},
          {
            form,
            thunk
          }
        )
      )

      return
    }
  }

  yield put(actions.closeSalesOrder.request(form))
  const { response, error } = yield call(api.closeSalesOrder, guid)

  if (response) {
    yield put(
      actions.closeSalesOrder.success(response, {
        form,
        thunk
      })
    )

    /* 
      this was actually causing an error because its all
      handled in pages/Layout/sagas -- SVE 3/23/2021
    */
    //   yield put({
    //     type: EXIT,
    //     meta: { form }
    //   })
  } else {
    yield put(
      actions.closeSalesOrder.failure(error, {
        form,
        thunk
      })
    )
  }
}

export function* closeSalesOrderListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk }
    } = yield take(CONSTANTS.CLOSE_SALES_ORDER.TRY)

    if (form === formListener) {
      yield fork(closeSalesOrderProcess, form, thunk)
    }
  }
}

export function* saveArgumentsSaga(form) {
  const formState = yield select(getFormSelector(form))
  const selectedWarehousePanel = getIn(formState, 'ui.warehousePanel') || null
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const guid = getIn(formState, 'guid')
  if (isMobile) {
    return {
      guid,
      groupNames: ['header', 'detail', 'final', 'signature'],
      inventoryNames: getInventoryNames(getIn(formState, 'meta.inventoryNames'))
    }
  }

  return {
    guid,
    inventoryNames: getInventoryNames(
      getIn(formState, 'meta.inventoryNames'),
      selectedWarehousePanel
    )
  }
}

/* check this one out, is it even being used? */
export const cancelEditGroupNamesSaga = function cancelEditGroupNamesSaga(
  form
) {
  return ['order', 'detail', 'final', 'activities']
}

export function* getRecordArgumentsSaga(form, ...args) {
  const formState = yield select(getFormSelector(form))

  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const customerId = getIn(formState, 'fields.customerId.value') || ''
  const guid = getIn(formState, 'guid')
  const activeShipmentCycle =
    getIn(formState, 'fields.activeShipmentCycle.value') || ''
  const showAllAudits = getIn(formState, 'fields.showAllAudits.value') || false
  const inventoryNames = getInventoryNames(
    getIn(formState, 'meta.inventoryNames')
  )

  const params = activeShipmentCycle
    ? {
        activeShipmentCycle,
        dataId,
        guid,
        recordName: null,
        properties: { customerId },
        showAll: showAllAudits,
        responses: null,
        inventoryNames
      }
    : {
        dataId,
        guid,
        recordName: null,
        properties: { customerId },
        showAll: showAllAudits,
        responses: null,
        inventoryNames
      }

  return params
}

export function* getSalesOrderTabProcess(
  form,
  groupNames = ['header', 'detail']
) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const isNew = getIn(formState, 'values.isNew') || false
  const guid = getIn(formState, 'guid')

  const params = {
    form,
    newRecord: isNew,
    tabIds: groupNames,
    dataId,
    guid,
    groupNames
  }

  yield fork(getRecord, params)
}

export function* saveSalesOrderProcess(
  form,
  generateId = false,
  manualDataIdEntry = false,
  saveAttemptPayload = {}
) {
  const formState = yield select(getFormSelector(form))

  const dataId = getIn(formState, 'fields.dataId.value') || null
  const guid = getIn(formState, 'guid')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const groupNames = isMobile
    ? ['header', 'detail', 'final', 'signature']
    : getGroupNames(formState)

  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])

  const hasBlankRow =
    lineItems.filter(row => row.get('rowId') === 'blankrow')?.size || false

  yield put(actions.saveSalesOrder.request(form))

  const { response, error } = yield call(api.saveSalesOrder, {
    dataId,
    generateId,
    guid,
    groupNames,
    keepEditing: false
  })

  if (response) {
    yield put(
      actions.saveSalesOrder.success(
        { record: { ...response }, hasBlankRow: Boolean(hasBlankRow) },
        form
      )
    )

    if (selectedPrimaryTab !== 'checkout' && !isMobile) {
      yield put(tryChangeFormTab(form, 'checkout'))
    }

    if (isMobile) {
      const hashBang = window?.location?.hash
      if (hashBang && hashBang !== '#/salesorder') {
        yield put(push('/salesorder'))
      }
    }

    if (
      saveAttemptPayload &&
      typeof saveAttemptPayload === 'object' &&
      Object.keys(saveAttemptPayload).length
    ) {
      if (saveAttemptPayload.depositData) {
        yield fork(depositInvoiceEntryProcess, form, saveAttemptPayload)
      }
    }
  } else {
    /*
      cannot assume there will be an error object or error.status etc
      due to accessOverride potential validation etc. -- SVE 10/9/19
    */
    if (
      error &&
      error.status &&
      error.status === 498 &&
      error.validationErrors
    ) {
      const messages = getErrorMessages(error.validationErrors)
      yield call(warningModal, messages, 'Attention!')
    }
    yield put(actions.saveSalesOrder.failure(error, form))
  }
}

export function* validationWarningProcess(error) {
  let belowCost = false
  if (error && error.status && error.data) {
    const errorMessageString = error.data.reduce((acc, next) => {
      if (next?.message.includes('Price is below cost')) {
        belowCost = true
      }
      acc = acc.concat(`${next.message}\n`)
      if (next.nestedValidations?.length) {
        next.nestedValidations.forEach(n => {
          if (n?.message.includes('Price is below cost')) {
            belowCost = true
          }
          acc = acc.concat(`${n.message}\n`)
        })
      }
      acc = acc.concat('\n')
      return acc
    }, '')
    if (belowCost) {
      yield fork(confirmationModal, errorMessageString, 'Attention!')
    } else {
      yield fork(warningModal, errorMessageString, 'Attention!')
      //
    }
  }
  return belowCost
}
export function* validateSalesOrderProcess(
  form,
  generateId = false,
  manualDataIdEntry = false,
  saveWithoutSignature = false,
  saveAttemptPayload = {},
  additionalApiParams = {}
) {
  let modalId
  const formState = yield select(getFormSelector(form))

  const dataId = getIn(formState, 'fields.dataId.value') || null
  const manualDataId = getIn(formState, 'fields.manualDataId.value') || null
  const guid = getIn(formState, 'guid')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const paymentOption = getIn(formState, 'ui.paymentOption') || 'cash'
  const isSuspended =
    getIn(formState, 'fields.isSuspended.value') ||
    getIn(formState, 'values.isSuspended') ||
    false

  const groupNames = isMobile
    ? ['detail', 'header', 'final', 'signature']
    : getGroupNames(formState)
  /*
    this is REALLY important, the API will not work for all customers
    in mobile without the signature being included in the groupNames
    -- SVE 12/15/2020
  */

  const defaultParams = {
    dataId: manualDataIdEntry ? manualDataId : dataId,
    generateId,
    guid,
    groupNames,
    keepEditing: true,
    saveWithoutSignature
  }

  let apiParams
  if (paymentOption === 'bill' && !isSuspended) {
    apiParams = {
      ...defaultParams,
      billToCustomer: true
    }
  } else {
    apiParams = { ...defaultParams }
  }

  if (
    additionalApiParams &&
    typeof additionalApiParams === 'object' &&
    additionalApiParams !== null
  ) {
    apiParams = {
      ...apiParams,
      ...additionalApiParams
    }
  }

  if (saveAttemptPayload?.billToAllowance) {
    apiParams = {
      ...apiParams,
      billToAllowance: true
    }
  }

  yield put(actions.validateSalesOrder.request(form))

  const { response, error } = yield call(api.validateSalesOrder, apiParams)

  if (response) {
    yield put(
      actions.validateSalesOrder.success({ record: { ...response } }, form)
    )

    if (response.messages && response.messages.length) {
      for (let i = 0; i < response.messages.length; i++) {
        const msg = response.messages[i]

        if (msg.type === 'Warning') {
          yield call(warningModal, msg.message, msg.modalTitle)
          yield take(REMOVE_MODAL)
        }

        if (msg.type === 'Error') {
          yield call(warningModal, msg.message, msg.modalTitle)
          yield take(REMOVE_MODAL)
        }
      }
    }

    if (response.valid) {
      yield fork(
        saveSalesOrderProcess,
        form,
        generateId,
        manualDataIdEntry,
        saveAttemptPayload
      )
    } else if (response.promptForSignature) {
      const modalOpts = {
        component: Signature,
        props: { form, modal: true }
      }
      const m = yield call(addModal, form, modalOpts)

      modalId = m.payload.id
      yield put(m)
      const action = yield take([
        CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS,
        DESTROY_FIELD
      ])
      if (action.type === DESTROY_FIELD) {
        yield call(confirmationModal, 'No Signature. Continue?', 'No Signature')
        const a = yield take([CONFIRMED, CANCELED])

        if (a.type === CONFIRMED) {
          yield fork(
            validateSalesOrderProcess,
            form,
            generateId,
            manualDataIdEntry,
            true,
            saveAttemptPayload
          )
        } else {
          yield put({
            type: CONSTANTS.SAVE_SALES_ORDER_CANCEL,
            meta: { form }
          })
        }
      } else {
        const contin = yield call(validateSave, {
          dataId,
          manualDataId,
          manualDataIdEntry,
          generateId,
          guid,
          groupNames
        })
        if (contin) {
          yield fork(
            saveSalesOrderProcess,
            form,
            generateId,
            manualDataIdEntry,
            saveAttemptPayload
          )
        } else {
          yield put(
            actions.validateSalesOrder.failure('an error has occured', form)
          )
        }
        //
      }
    } else {
      yield fork(displaySalesOrderNumberEntryModal, form)
    }
  } else {
    yield put(actions.validateSalesOrder.failure(error, form))
    let belowCost = false
    /*
      cannot assume there will be an error object or error.status etc
      due to accessOverride potential validation etc. -- SVE 10/9/19
    */
    if (
      error &&
      error.status &&
      error.status === 498 &&
      error.validationErrors
    ) {
      const errorMessageString = error.validationErrors.reduce((acc, next) => {
        acc = acc.concat(`${next.message}\n`)
        if (next?.message.includes('Price is below cost')) {
          belowCost = true
        }
        return acc
      }, '')
      if (belowCost) {
        debugger
      } else {
        yield call(warningModal, errorMessageString, 'Attention!')
      }
    }

    if (error?.status === 499) {
      if (
        error?.message === 'Serial Number Input Required' ||
        error?.message === 'Lot Input Required'
      ) {
        const initialRequestParams = {
          generateId,
          manualDataIdEntry,
          saveWithoutSignature,
          saveAttemptPayload
        }
        yield fork(
          openSerialNumberEditor,
          form,
          error?.data,
          initialRequestParams
        )
      }
      if (error?.message === 'Validation Warning Message') {
        const isBelow = yield call(validationWarningProcess, error)
        if (isBelow) {
          //
          const action = yield take([CONFIRMED, CANCELED])
          if (action.type === CANCELED) {
            yield call(api.validateSalesOrder, {
              ...apiParams,
              cancel: true
            })
            return
          }
        }
        //

        yield take(REMOVE_MODAL)

        yield fork(
          validateSalesOrderProcess,
          form,
          generateId,
          manualDataIdEntry,
          true,
          saveAttemptPayload
        )
      }
    }

    if (error?.status === 404 && error?.message) {
      yield call(warningModal, error.message, 'Attention!')
    }

    if (saveAttemptPayload?.billToAllowance) {
      yield call(api.validateSalesOrder, {
        ...apiParams,
        billToAllowance: null,
        cancel: true
      })
    }
  }

  if (modalId) {
    yield put(removeModal(form, modalId))
  }
}

export function* saveSalesOrderListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_SALES_ORDER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      /*
        the payload from the arguments here in changeset 24051. Not sure
        why but that broke stuff and its been added back -- SVE 12/10/2020
      */
      yield fork(checkSalesOrderValidation, form, payload)
    }
  }
}

export function* displaySalesOrderNumberEntryModal(form) {
  const modalOpts = {
    component: SalesOrderNumberEntryModal,
    options: {
      width: 400,
      title: 'Choose ID',
      data: {
        form,
        option:
          '' /* option is used in checkoutSagas, and this component is shared -- SVE 2/13/2020 */,
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              try {
                cb()
                await this.props.dispatch(
                  actions.validateSalesOrder.try(form, {
                    manualDataIdEntry: true
                  })
                )
              } finally {
                /* never gets here */
                if (cb) {
                  cb()
                }
              }
            }
          },
          {
            primary: true,
            title: 'System ID',
            async clickEvent(args, fs, cb) {
              //
              try {
                cb()
                await this.props.dispatch(
                  actions.validateSalesOrder.try(form, { generateId: true })
                )
              } finally {
                if (cb) {
                  /* never gets here */
                  cb()
                }
              }
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.clearManualDataId(form, { isCheckout: false })
                )
              } finally {
                if (cb) {
                  cb()
                }
              }
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* checkSalesOrderValidation(form, saveAttemptPayload = {}) {
  const formState = yield select(getFormSelector(form))
  const valid = getIn(formState, 'values.valid')

  if (valid) {
    yield fork(saveSalesOrderProcess, form, false, false, saveAttemptPayload)
  } else {
    yield fork(
      validateSalesOrderProcess,
      form,
      false,
      false,
      false,
      saveAttemptPayload
    )
  }
}

export function* saveSuccessListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_SALES_ORDER.SUCCESS)
    const {
      meta: { form },
      payload: {
        propertyChanged = 'customerId',
        messages = [],
        cancelledChanges = {},
        record = {}
      }
    } = action

    if (form === formListener) {
      yield fork(
        handleInteractiveModalMessages,
        form,
        messages,
        cancelledChanges,
        record,
        propertyChanged,
        true
      )
    }
  }
}

export function* validateSalesOrderListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { generateId, manualDataIdEntry }
    } = yield take(CONSTANTS.VALIDATE_SALES_ORDER.TRY)

    if (form === formListener) {
      yield fork(validateSalesOrderProcess, form, generateId, manualDataIdEntry)
    }
  }
}

export function* createNewSalesOrderListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CREATE_NEW.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(createNewSalesOrderProcess, form)
    }
  }
}

export function* handleUIAfterNewSalesOrderProcess(
  form,
  isMobile = false,
  selectedPrimaryTab
) {
  if (!isMobile) {
    yield put(updateFormTitle(form, 'Sales Order'))

    if (selectedPrimaryTab !== 'order') {
      yield put(tryChangeFormTab(form, 'order'))
    }
  } else {
    const hashBang = window?.location?.hash
    if (hashBang && hashBang !== '#/salesorder') {
      yield put(push('/salesorder'))
    }
  }
}

export function* createNewSalesOrderProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const customerId = getIn(formState, 'fields.customerId.value') || null
  const guid = getIn(formState, 'guid')
  const isNew = getIn(formState, 'values.isNew') || false
  const isEditing = getIn(formState, 'isEditing') || false
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  const hasPaymentsChanged =
    getIn(formState, 'values.hasPaymentsChanged') || false

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const groupNames = isMobile ? mobileGroupNames : getGroupNames(formState)

  // Warn customer when they are editing,
  // else clear the whole screen
  if (isEditing && !hasPaymentsChanged) {
    yield call(
      salesOrderConfirmationModal,
      form,
      'Are you sure you want to create a new order? All changes will be lost.',
      'Create New?'
    )

    const action = yield take([CONFIRMED, CANCELED])

    if (action.type === CONFIRMED) {
      yield put(actions.createNewSalesOrder.request(form))

      const { response, error } = yield call(api.cancelSalesOrderEdit, {
        dataId,
        guid,
        customerId: action.payload.retainCustomer ? customerId : '',
        createNew: action.payload.retainCustomer || dataId !== null,
        groupNames
      })

      if (response) {
        if (!action.payload.retainCustomer) {
          yield put(resetMasterFields(form))
        }

        yield put(actions.createNewSalesOrder.success(response, form))
        yield fork(
          handleUIAfterNewSalesOrderProcess,
          form,
          isMobile,
          selectedPrimaryTab
        )
      } else {
        yield put(actions.createNewSalesOrder.failure(error, form))
      }
    }
  } else {
    const { response, error } = yield call(api.cancelSalesOrderEdit, {
      dataId,
      guid,
      customerId: isNew ? customerId : '',
      createNew: true,
      groupNames
    })

    if (response) {
      if (!isNew) {
        yield put(resetMasterFields(form))
      }

      yield putResolve(actions.createNewSalesOrder.success(response, form))
      yield fork(
        handleUIAfterNewSalesOrderProcess,
        form,
        isMobile,
        selectedPrimaryTab
      )
    } else {
      yield put(actions.createNewSalesOrder.failure(error, form))
    }
  }
}

export function* salesOrderConfirmationModal(
  form,
  message,
  title,
  opts = {},
  confirm,
  cancel
) {
  const options = {
    component: Dialog,
    options: {
      actions: RetainCustomerModalActions,
      data: {
        message
      },
      modalOverrideClass: '',
      title: title || 'Cancel?',
      type: 'confirm',
      width: 500
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
  return modal.payload.id
}

export function* editNotesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LOCK_SALES_ORDER_NOTES.TRY)
    const {
      meta: { form },
      payload: { type }
    } = action

    if (form === formListener) {
      yield fork(editNotesProcess, form, type)
    }
  }
}

export function* editNotesProcess(form, type) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null

  yield put(actions.lockSalesOrderNotes.request(form))

  const { response, error } = yield call(api.lockSalesOrderNotes, {
    dataId,
    guid,
    notesName: type
  })

  if (response) {
    yield put(actions.lockSalesOrderNotes.success(response, form))
  } else {
    yield put(actions.lockSalesOrderNotes.failure(error, form))
  }
}

export function* saveNotesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_SALES_ORDER_NOTES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (payload && payload.deleteNote) {
        yield fork(deleteNoteProcess, form, payload)
      } else {
        yield fork(saveNotesProcess, form, payload)
      }
    }
  }
}

export function* saveNotesProcess(form, payload) {
  const { deleteNote = false, type, modal } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const note = !deleteNote ? getIn(formState, `fields.${type}.value`) : ''

  yield put(actions.saveSalesOrderNotes.request(form))

  const { response, error } = yield call(api.saveSalesOrderNotes, {
    dataId,
    notesName: type,
    note,
    guid
  })

  if (response) {
    yield put(actions.saveSalesOrderNotes.success(response, form))
    yield put(confirm(form, modal.id))
  } else {
    yield put(actions.saveSalesOrderNotes.failure(error, form))
  }
}

/* saveNotesProcess and deleteNoteProcess can probably be combined when time permits -- SVE 1/29/2021 */
export function* deleteNoteProcess(form, payload) {
  const { type, modal } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null

  yield call(
    confirmationModal,
    'Do you wish to delete this Note?',
    'Internal Notes'
  )

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CONFIRMED) {
    yield put(actions.saveSalesOrderNotes.request(form))

    const { response, error } = yield call(api.saveSalesOrderNotes, {
      dataId,
      notesName: type,
      note: '',
      guid
    })

    if (response) {
      yield put(actions.saveSalesOrderNotes.success(response, form))
      yield put(confirm(form, modal.id))
      yield put(actions.deleteSalesOrderNotes.success(response, form))
    } else {
      yield put(actions.saveSalesOrderNotes.failure(error, form))
    }
  }
}

export function* cancelNotesEditListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CANCEL_SALES_ORDER_NOTES_EDIT.TRY)
    const {
      meta: { form },
      payload: { type }
    } = action

    if (form === formListener) {
      yield fork(cancelNotesEditProcess, form, type)
    }
  }
}

export function* cancelNotesEditProcess(form, type) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null

  yield put(actions.cancelSalesOrderNotesEdit.request(form))

  const { response, error } = yield call(api.cancelSalesOrderNotesEdit, {
    dataId,
    guid,
    notesName: type
  })

  if (response) {
    yield put(actions.cancelSalesOrderNotesEdit.success(response, form))
  } else {
    yield put(actions.cancelSalesOrderNotesEdit.failure(error, form))
  }
}

export function* cancelSalesOrderEditListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_SALES_ORDER_EDIT.TRY)

    if (form === formListener) {
      yield fork(cancelSalesOrderEditProcess, form)
    }
  }
}

export function* cancelNewSalesOrderEditListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CANCEL_NEW_SALES_ORDER_EDIT.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(cancelNewSalesOrderEditProcess, form)
    }
  }
}

export function* duplicatePrimaryGridDataCheckProcess(action) {
  const {
    payload: { field, propertyName, value },
    meta: { form },
    meta
  } = action

  const formState = yield select(getFormSelector(form))
  const grid = getIn(formState, `fields.${propertyName}.rowData`) || fromJS([])
  const isDuplicate = grid.find(x => x.get(field) === value)
  const isQuote = getIn(formState, 'fields.quote.value') || false
  const suppressDuplicateMessage = getIn(
    formState,
    'meta.suppressDuplicateProductMessageInSalesOrder'
  )

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const successArgs = isMobile
    ? {
        meta,
        payload: { propertyName, field, value },
        type: 'TRY_CELL_CHANGED_SUCCESS'
      }
    : {
        meta,
        type: 'TRY_CELL_CHANGED_SUCCESS'
      }

  const failureArgs = isMobile
    ? {
        meta,
        error: true,
        payload: { propertyName, field, value: '' },
        type: 'TRY_CELL_CHANGED_FAILURE'
      }
    : {
        meta,
        error: true,
        type: 'TRY_CELL_CHANGED_FAILURE'
      }

  /* if we have a duplicate record, throw a modal and clear out the row */
  const success = put(successArgs)
  const failure = put(failureArgs)

  if (isDuplicate && value) {
    if (suppressDuplicateMessage != null) {
      if (
        !suppressDuplicateMessage ||
        (suppressDuplicateMessage === 'O' && isQuote) ||
        (suppressDuplicateMessage === 'Q' && !isQuote)
      ) {
        /* otherwise we need a confirmation modal here */
        const duplicateMessageHeader = 'Duplicate Product'

        const duplicateMessage =
          isDuplicate?.get &&
          isDuplicate.get('dataId') &&
          isDuplicate.get('description') &&
          isDuplicate.get('lineNumber')
            ? `${isDuplicate.get('dataId')} ${isDuplicate.get(
                'description'
              )} already found on line ${isDuplicate.get(
                'lineNumber'
              )}. Continue?`
            : 'This item already exists. Continue?'

        yield call(
          confirmationModal,
          duplicateMessage,
          duplicateMessageHeader,
          { modalContainerStyle: { zIndex: 1301 } }
        )
        const act = yield take([CONFIRMED, CANCELED])

        if (act.type === CONFIRMED) {
          yield success
        } else {
          yield failure
        }
        /* end confirmation modal logic */
      } else {
        yield success
      }
    }
  } else {
    yield success
  }
}

export function* quantityValidation(action, skipBoxQuantityRoutine = false) {
  const {
    payload: { value, data, node },
    meta: { form },
    meta
  } = action

  const { dataId, description, lineNumber, rowId } = data
  const formState = yield select(getFormSelector(form))
  let resolvedValue = value
  const isValid = isValidQuantityInput(resolvedValue)
  const boxQuantityResolved = getIn(data, 'boxQuantityResolved') || null
  const cartonQuantityResolved = getIn(data, 'cartonQuantityResolved') || null
  const roundToBoxQuantity = getIn(data, 'roundToBoxQuantity') || false

  if (value < 0) {
    /* handle returns */
    yield fork(returnProductProcess, form, meta, value, data, node)
    return
  }

  if (skipBoxQuantityRoutine) {
    yield put({
      meta,
      type: 'TRY_CELL_CHANGED_SUCCESS',
      payload: value
    })

    return
  }

  if (roundToBoxQuantity) {
    let suppressedLineItems = getIn(
      formState,
      'values.listOfLineItemIdsSuppressed'
    )
    suppressedLineItems =
      suppressedLineItems && suppressedLineItems?.toJS
        ? suppressedLineItems.toJS()
        : []

    if (!isValid) {
      resolvedValue = ''
    }

    const k = extractString(value)

    resolvedValue = calculateResolvedQuantity(
      value,
      k,
      boxQuantityResolved,
      cartonQuantityResolved
    )

    // prompt user for box quantity IF boundToBoxQuantity
    /*
      note if we want to suppress box quantity validation if the user enters
      a 'c' keyword for carton, bring back the original IF condition that was
      if (k.toLowerCase() !== 'c' && !suppressedLineItems.includes(rowId))
      INFORM has a bug when entering 'C' for some products
      -- SVE 12/17/2021
    */
    if (!suppressedLineItems.includes(rowId)) {
      const isMultiple = resolvedValue % boxQuantityResolved === 0
      const roundedValue = roundToNextMultiple(
        resolvedValue,
        boxQuantityResolved
      )

      const isResolvedValue = roundedValue === resolvedValue

      if (!isMultiple && !isResolvedValue) {
        yield call(
          confirmationModal,
          `${resolvedValue} is not a box quantity multiple of ${boxQuantityResolved}. Round to ${roundedValue} ?`,
          `Warning: ${dataId} - "${description}"`,
          { form },
          { title: 'Yes' },
          { title: 'No' }
        )

        const act = yield take([CONFIRMED, CANCELED])
        if (act.type === CONFIRMED) {
          resolvedValue = roundedValue
          yield put({
            meta,
            type: 'TRY_CELL_CHANGED_SUCCESS',
            payload: resolvedValue
          })
          // node.gridApi.stopEditing()
        } else {
          // if no, set flag to stop prompt
          yield put(actions.supressBoxQuantityPrompt(form, { rowId }))
          yield put({
            meta,
            type: 'TRY_CELL_CHANGED_SUCCESS',
            payload: resolvedValue
          })
        }
      } else {
        yield put({
          meta,
          type: 'TRY_CELL_CHANGED_SUCCESS',
          payload: roundedValue
        })
      }
    } else {
      /*
        Marc -- this is all I added to the logic of this routine, and this fixes
        the following issue. If you don't have this 'else' statement and action in place,
        you cannot change the Quantity Ordered of products after the user cancels out of
        the Box Quantity prompt -- SVE 1/3/2020
      */
      yield put({
        meta,
        type: 'TRY_CELL_CHANGED_SUCCESS',
        payload: value
      })
    }
  } else {
    yield put({
      meta,
      type: 'TRY_CELL_CHANGED_SUCCESS',
      payload: value
    })
  }
}

export function* tryCellChangedListener(formListener) {
  while (true) {
    const action = yield take('TRY_CELL_CHANGED_REQUEST')
    const {
      payload: {
        field,
        propertyName,
        value,
        data,
        gridApi,
        skipBoxQuantityRoutine = false,
        rowIndex
      },
      meta: { form },
      meta
    } = action

    const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
      false

    if (form === formListener) {
      if (
        propertyName === 'lineItems' ||
        propertyName === 'mobileSalesOrderProductId' ||
        (propertyName && propertyName.match(/lineItemComponents/))
      ) {
        if (field === 'dataId') {
          yield fork(duplicatePrimaryGridDataCheckProcess, action)
        } else if (field === 'quantity' || field === 'quantityBO') {
          if (isMobile) {
            yield fork(quantityToShipProcessMobile, action)
          } else {
            yield fork(quantityToShipProcess, action)
          }
        } else if (
          field === 'quantityOrdered' ||
          field === 'quantityExtended'
        ) {
          if (isMobile) {
            yield fork(quantityValidationMobile, action, skipBoxQuantityRoutine)
          } else {
            yield fork(quantityValidation, action, skipBoxQuantityRoutine)
          }
        } else {
          yield put({
            meta,
            type: 'TRY_CELL_CHANGED_SUCCESS',
            payload:
              isMobile && propertyName !== 'mobileSalesOrderProductId'
                ? {
                    propertyName,
                    value,
                    field,
                    data,
                    rowIndex
                  }
                : value
          })
        }
      } else if (propertyName.includes('groups')) {
        if (field === 'dataId') {
          yield fork(addItemGroupProcess, form, {
            field,
            propertyName,
            value,
            data,
            gridApi,
            rowIndex
          })
        } else {
          yield fork(changeItemGroupsGridProcess, form, {
            field,
            propertyName,
            value,
            data,
            gridApi
          })
        }
      } else if (propertyName === 'amount') {
        yield fork(changeRecalculatePricesProcess, form, action.payload)
      }
    }
  }
}

export function* voidSalesOrderListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.VOID_SALES_ORDER.TRY)
    const {
      meta: { form },
      payload: { preSave = false, modalId }
    } = action

    if (form === formListener) {
      if (preSave) {
        // preSave: After void modal comes up but before clicking 'ok' to void
        yield fork(confirmVoidSalesOrderProcess, form, modalId)
      } else {
        // after click Void button, to open the Void Modal
        yield fork(preVoidSalesOrder, form)
      }
    }
  }
}

export function* preVoidSalesOrder(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)

  yield put(actions.voidSalesOrder.request(form))

  const { response, error } = yield call(api.validateSalesOrder, {
    guid,
    action: 'void',
    groupNames
  })

  if (
    response &&
    response.valid &&
    is.array(response.messages) &&
    !response.messages.length
  ) {
    yield fork(openVoidModalProcess, form)
  } else if (
    response &&
    is.array(response.messages) &&
    response.messages.length
  ) {
    // for void, there is no case where more than 1 message comes back
    // - LL 10/28/19
    const msg = response.messages[0]
    const { message, modalTitle, type } = msg

    if (type === 'Information') {
      yield call(confirmationModal, message, modalTitle)
      const action = yield take([CONFIRMED, CANCELED])

      if (action.type === CONFIRMED) {
        yield fork(openVoidModalProcess, form)
      } else {
        yield put(actions.voidSalesOrder.failure(error, form))
      }
    }
  } else {
    const { status, validationErrors, message } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    } else if (status === 496) {
      yield call(warningModal, message, 'Attention!')
    }
    yield put(actions.voidSalesOrder.failure({}, form))
  }

  return null
}

export function* confirmVoidSalesOrderProcess(form, id) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const groupNames = getGroupNames(formState)
  const userId = getIn(formState, 'fields.userId.value') || null
  const reason = getIn(formState, 'fields.reason.value') || ''
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  yield put(actions.voidSalesOrder.request(form))

  const { response, error } = yield call(api.saveSalesOrder, {
    dataId,
    guid,
    action: 'void',
    userId,
    reason,
    groupNames,
    keepEditing: false
  })

  if (response) {
    yield put(actions.voidSalesOrder.success(response, form))
    yield put(confirm(form, id))

    if (selectedPrimaryTab !== 'order' && !isMobile) {
      yield put(tryChangeFormTab(form, 'order'))
    }

    if (isMobile) {
      const hashBang = window?.location?.hash
      if (hashBang && hashBang !== '#/salesorder') {
        yield put(push('/salesorder'))
      }
    }
  } else {
    const { status, validationErrors } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
    yield put(
      actions.voidSalesOrder.failure({ ...error, clearId: false }, form)
    )
    yield put(canceled(form, id))
  }

  return null
}

export function* openVoidModalProcess(form) {
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const userDescription = yield select(state =>
    getIn(state, 'auth.description')
  ) || ''

  const modalOpts = {
    component: isMobile ? VoidSalesOrderModalMobile : VoidSalesOrderModal,
    options: {
      width: isMobile ? 400 : 600,
      title: 'Cancel/Void',
      data: {
        actions: [
          {
            primary: true,
            title: 'OK',
            disabled: fs => {
              const { fields, isPosting = false } = fs
              const reason = fields?.reason?.value || ''

              if (isPosting) {
                return true
              }
              return !reason
            },
            async clickEvent(args, fs, cb) {
              try {
                const { id } = args
                // cb()
                await this.props.dispatch(
                  actions.voidSalesOrder.try(form, {
                    preSave: true,
                    modalId: id
                  })
                )
              } finally {
                if (cb) {
                  cb()
                }
              }
            }
          },
          {
            cancel: true,
            secondary: true,
            title: 'Cancel',
            disabled: fs => {
              const { isPosting = false } = fs
              return isPosting
            }
          }
        ],
        form
      }
    }
  }

  // have to set the default userDescription manually because it is not in the dto
  // 11/4/19 LL
  yield put(setField(form, 'userDescription', userDescription))

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CANCELED) {
    yield put(actions.voidSalesOrder.failure({ clearId: true }, form))
  }

  return modal.payload.id
}

export function* changeCycleListener(formListener) {
  while (true) {
    const action = yield take(SET_FIELD)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener && payload) {
      if (payload.propertyName === 'activeShipmentCycle') {
        yield fork(changeCycleProcess, form, payload)
      }
    }
  }
}

export function* changeCycleProcess(form, payload) {
  const { value } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(api.readSalesOrder, {
    activeShipmentCycle: value,
    dataId,
    guid,
    groupNames: ['shipments', 'signature']
  })

  if (response) {
    yield put(
      actions.onPropertyChange.success(
        {
          propertyChanged: 'activeShipmentCycle',
          record: response
        },
        form
      )
    )
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
  }
}

export function* clearShipmentStatusListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CLEAR_SHIPMENT_STATUS.TRY)
    const {
      meta: { form },
      payload: { dataId }
    } = action

    if (form === formListener) {
      yield fork(clearShipmentStatusProcess, form, dataId)
    }
  }
}

export function* clearShipmentStatusProcess(form, dataId) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const activeShipmentCycle =
    getIn(formState, 'fields.activeShipmentCycle.value') || ''

  yield put(actions.clearShipmentStatus.request(form))

  const { response, error } = yield call(api.changeGridItem, {
    properties: {
      dataId
    },
    guid,
    activeShipmentCycle,
    gridName: 'shipmentStatuses',
    groupNames: ['shipments', 'signature']
  })

  if (response) {
    yield put(
      actions.onPropertyChange.success(
        {
          propertyChanged: 'shipmentStatuses',
          ...response
        },
        form
      )
    )
  } else {
    yield put(actions.clearShipmentStatus.failure(error, form))
  }
}

/*
  this function is funky and can definitely be merged
  with changeCycleListener above, and most likely merged
  with other SET_FIELD listener patterns that are throughout
  SOE -- SVE 2/1/21
*/
export function* setUserIdDescriptionListener(formListener) {
  while (true) {
    const action = yield take(SET_FIELD)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener && payload) {
      if (payload.propertyName === 'userId' && payload.results != null) {
        const {
          results: { description = '' } = {
            description: ''
          }
        } = payload
        yield put(setField(form, 'userDescription', description))
      }
    }
  }
}

export function* releaseCreditHoldListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.RELEASE_CREDIT_HOLD.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(releaseCreditHoldProcess, form)
    }
  }
}

export function* releaseCreditHoldProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const guid = getIn(formState, 'guid')
  const isEditing = getIn(formState, 'isEditing') || false
  const groupNames = getGroupNames(formState)

  if (isEditing) {
    yield call(validateSalesOrderProcess, form)
  }

  yield put(actions.releaseCreditHold.request(form))

  const { response, error } = yield call(api.propertyChange, {
    dataId,
    guid,
    action: 'releasecredithold',
    groupNames
  })

  if (response) {
    yield put(actions.releaseCreditHold.success(response, form))
    yield put(
      actions.onPropertyChange.success({ record: { ...response } }, form)
    )
  } else {
    yield put(actions.releaseCreditHold.failure(error, form))
  }
}

export function* validateSave(params) {
  const {
    manualDataIdEntry,
    manualDataId,
    dataId,
    generateId,
    guid,
    groupNames
  } = params
  let exit = false
  while (!exit) {
    const { response, error } = yield call(api.validateSalesOrder, {
      dataId: manualDataIdEntry ? manualDataId : dataId,
      generateId,
      guid,
      groupNames,
      keepEditing: true
    })

    if (response && response.valid) {
      exit = true
    }
    yield delay(500)
    return exit
  }
}

export function* cancelEditAfterClearListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_EDIT_AFTER_CLEAR.TRY)

    if (form === formListener) {
      yield call(cancelEditAfterClear, form)
    }
  }
}

export function* openCutLengthModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber }
    } = yield take(CONSTANTS.OPEN_CUT_LENGTH_MODAL)

    if (form === formListener) {
      yield fork(launchCutLengthModal, form, lineNumber)
    }
  }
}

export function* launchCutLengthModal(form, lineNumber) {
  const modalOpts = {
    component: CutLengthModal,
    options: {
      maxHeight: '100%',
      width: '300px',
      title: 'Custom Cutting Parameters',
      data: {
        form,
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              const {
                pieces,
                feet,
                inches,
                numerator,
                divisor
              } = fs.fields.customCut

              this.props.dispatch(
                actions.calculateCustomCut.try(form, {
                  lineNumber,
                  pieces: pieces?.value,
                  feet: feet?.value,
                  inches: inches?.value,
                  numerator: numerator?.value,
                  divisor: divisor?.value,
                  cb
                })
              )
            }
          },
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, fs, cb) {
              cb()
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* calculateCustomCutListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.CALCULATE_CUSTOM_CUT.TRY)

    if (form === formListener) {
      yield fork(calculateCustomCutProcess, form, payload)
    }
  }
}

export function* calculateCustomCutProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const guid = getIn(formState, 'guid')

  const { pieces, feet, inches, numerator, divisor, lineNumber, cb } = payload

  yield put(actions.calculateCustomCut.request(form))

  const { response, error } = yield call(api.changeGridItem, {
    lineNumber,
    gridName: 'lineitems',
    groupNames: ['detail'],
    properties: {
      customCutParameters: { pieces, feet, inches, numerator, divisor }
    },
    dataId,
    guid
  })

  if (response) {
    yield put(actions.calculateCustomCut.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    yield put(actions.calculateCustomCut.failure(error, form))
  }
}

export function* openSpawnedOrdersModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { dataId, rowIndex, spawnedOrders }
    } = yield take(CONSTANTS.OPEN_SPAWNED_ORDERS_MODAL.TRY)


    if (form === formListener) {
      yield fork(launchSpawnedOrdersModal, form, spawnedOrders)
    }
  }
}
export function* launchSpawnedOrdersModal(form, data) {
  const modalOpts = {
    component: SpawnedOrdersModal,
    options: {
      maxHeight: '100%',
      width: '1000px',
      title: 'Spawned Orders',
      data: {
        form,
        rowData: data,
        actions: [
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, fs, cb) {
              cb()
            }
          }
        ]
      }
    }
  }
  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export default function* salesOrderSagas(form) {
  yield fork(editableGridSagas, form)
  yield fork(detailSagas, form)
  yield fork(itemGroupSagas, form)
  yield fork(tabListener, form)
  yield fork(closeSalesOrderListener, form)
  yield fork(headerSagas, form)
  yield fork(searchAreaSagas, form)
  yield fork(saveSalesOrderListener, form)
  yield fork(saveSuccessListener, form)
  yield fork(validateSalesOrderListener, form)
  yield fork(invoicingSagas, form)
  yield fork(gridSagas, form)
  yield fork(createNewSalesOrderListener, form)
  yield fork(editNotesListener, form)
  yield fork(saveNotesListener, form)
  yield fork(cancelNotesEditListener, form)
  yield fork(cancelSalesOrderEditListener, form)
  yield fork(cancelNewSalesOrderEditListener, form)
  yield fork(tryCellChangedListener, form)
  yield fork(voidSalesOrderListener, form)
  yield fork(copyOrderSagas, form)
  yield fork(changeCycleListener, form)
  yield fork(clearShipmentStatusListener, form)
  yield fork(setUserIdDescriptionListener, form)
  yield fork(releaseCreditHoldListener, form)
  yield fork(fastEntitySagas, form)
  yield fork(activitiesSagas, form)
  yield fork(additionalAuditsDataFlagListener, form)
  yield fork(infoPanelSagas, form)
  yield fork(externalHandlerSagas, form)
  yield fork(checkoutSagas, form)
  yield fork(returnOrderSagas, form)
  yield fork(notesHandlerSagas, form)
  yield fork(attachmentsSagas, form)
  yield fork(saveLayoutForUserAutenticationListener, form)
  yield fork(recalculatePricesSagas, form)
  yield fork(cancelEditAfterClearListener, form)
  yield fork(customerStockMinimumsSagas, form)
  yield fork(openCutLengthModalListener, form)
  yield fork(calculateCustomCutListener, form)
  yield fork(warrantyTagSagas, form)
  yield fork(getSalesmenCommissionsListener, form)
  yield fork(changeCommissionValueListener, form)
  yield fork(commissionIgnoreMinimumsSwitchListener, form)
  yield fork(triggerShippingGridBulkChangeListener, form)
  yield fork(importAutoQuoteListener, form)
  yield fork(serialNumberSagas, form)
  yield fork(backorderCommitmentSagas, form)
  yield fork(rebateSagas, form)
  yield fork(recurringOrderSagas, form)
  yield fork(openSpawnedOrdersModalListener, form)
  yield fork(customAssemblySagas, form)
  yield fork(shipperHQSagas, form)
}
