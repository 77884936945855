/* eslint react/jsx-fragments: 0, jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0, no-else-return: 0, react/sort-comp: 0 */
import React, { Component, Fragment } from 'react'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { GridField, withContext } from 'ddiForm'
import { getClientHeight } from 'pages/CustomerOrderPad/utils'
import NumericCellEditor from 'pages/CustomerOrderPad/NumericCellEditor'
import { Select, MenuItem } from '@material-ui/core'
import shortid from 'shortid'
import {
  getIn,
  getField,
  getValue,
  getMeta,
  emptyList,
  is,
  parseNumber,
  plainDeepEqual
} from 'utils'

import {
  showPriceDetail,
  showAdjustedPrice,
  showCustomerStockMinimums,
  updateLineItemQuantity
} from 'pages/CustomerOrderPad/actions'

import { readCustomerStockMinimums } from 'pages/InvoiceInquiry/actions'

import CurrentPrice from 'mobile/pages/OrderPad/components/CurrentPrice'
import CurrentPriceTooltip from './CurrentPriceTooltip'

const getRowNodeId = data => data.recordId

/* WIP */
let _isMounted = false

class InquiryResults extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    isModal: PropTypes.bool,
    filterInterfaceDimensions: PropTypes.object.isRequired,
    rowData: PropTypes.array,
    rowHeight: PropTypes.number
  }

  static defaultProps = {
    isMobile: false,
    isModal: false,
    rowData: [],
    rowHeight: 27
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.groupBy) {
      const groupBy = nextProps?.groupBy
      const sortBy = groupBy === 'T' ? 0 : 2
      if (prevState.groupBy !== groupBy) {
        return {
          groupBy,
          sortBy
        }
      }
    }

    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      // filterBy: 'A',
      groupBy: props.defaultGroupBy,
      sortBy: props.defaultGroupBy === 'T' ? 0 : 2
    }
  }

  componentDidMount() {
    _isMounted = true
  }

  componentWillUnmount() {
    _isMounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    const prevRowData = prevProps?.rowDataList?.toJS
      ? prevProps.rowDataList.toJS()
      : []
    const rowData = this?.props?.rowDataList?.toJS
      ? this?.props?.rowDataList.toJS()
      : []

    if (this.props.filterBy !== prevProps.filterBy) {
      this.updateGridOnFilterChange(this.props.filterBy)
    }

    if (this.props.groupBy !== prevProps.groupBy) {
      this.updateGridOnFilterChange(this.props.filterBy)
    }

    if (this.props.hits !== prevProps.hits) {
      this.updateGridOnFilterChange(this.props.hits)
    }

    if (this.props.vendorId !== prevProps.vendorId) {
      this.updateGridOnFilterChange(this.props.vendorId)
    }

    if (this.props.productLineId !== prevProps.productLineId) {
      this.updateGridOnFilterChange(this.props.productLineId)
    }

    if (this.props.cutOffDate !== prevProps.cutOffDate) {
      this.updateGridOnFilterChange(this.props.cutOffDate)
    }

    if (
      !plainDeepEqual(rowData, prevRowData) &&
      this.props.groupBy &&
      !this.props.isMobile
    ) {
      const gridApi =
        this?.inquiryResultsGrid?.connectedGrid?.current?.api || null

      if (gridApi && _isMounted) {
        if (rowData?.length) {
          const focusedCell = gridApi.getFocusedCell()
          const focalPoint =
            focusedCell && is.number(focusedCell?.rowIndex)
              ? focusedCell.rowIndex
              : this.props.groupBy === 'N' || this.props.groupBy === 'S'
              ? 0
              : 1
          setTimeout(() => {
            gridApi.setFocusedCell(focalPoint, 'quantity')
            gridApi.startEditingCell({
              rowIndex: focalPoint,
              colKey: 'quantity',
              keyPress: 0
            })
          }, 100)
        } else {
          setTimeout(() => {
            gridApi.clearFocusedCell()
          }, 100)
        }
      }
    }
  }

  generateSortOptions = () => {
    const { groupBy, rowDataList } = this.props
    const rowData = rowDataList?.toJS ? rowDataList.toJS() : []

    if (groupBy) {
      let menuOptions = []
      const toggleOptions = []
      // V: vendor, P: product line, T: hits
      if ((groupBy === 'V' || groupBy === 'P' || groupBy === 'T') && rowData) {
        switch (groupBy) {
          case 'V':
            menuOptions = [
              { value: 2, name: 'Primary Vendor Name Ascending' },
              { value: 3, name: 'Primary Vendor Name Descending' },
              { value: 0, name: 'Primary Vendor ID Ascending' },
              { value: 1, name: 'Primary Vendor ID Descending' }
            ]
            break
          case 'P':
            menuOptions = [
              { value: 2, name: 'Product Line Name Ascending' },
              { value: 3, name: 'Product Line Name Descending' },
              { value: 0, name: 'Product Line ID Ascending' },
              { value: 1, name: 'Product Line ID Descending' }
            ]
            break
          case 'T':
            menuOptions = [
              { value: 0, name: 'Descending' },
              { value: 1, name: 'Ascending' }
            ]
            break
          default:
            menuOptions = []
        }
      }

      if (rowData.length && menuOptions.length) {
        return (
          <div
            className="clear"
            style={{
              background: '#f5f7f7',
              border: '1px solid #BDC3C7',
              borderBottom: 0,
              padding: '3px 10px'
            }}
          >
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <label
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontSize: 11,
                  margin: '0 10px 0 0'
                }}
              >
                Sort By:
              </label>
              <Select value={this.state.sortBy} onChange={this.sortBy}>
                {menuOptions.map((item, idx) => (
                  <MenuItem value={item.value} key={shortid.generate()}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )
      }
    }

    return null
  }

  doesExternalFilterPass = node => {
    const { filterBy, groupBy, vendorId, productLineId, hits } = this.props

    const {
      data: {
        numberOfPurchases,
        isDueToBuy,
        isHidden,
        isHistory,
        isQuoted,
        productLine,
        vendor: { dataId }
      }
    } = node

    const filterParams = {
      A: () => {
        if (vendorId && groupBy === 'V') {
          return isHidden === false && dataId === vendorId
        }
        if (productLineId && groupBy === 'P') {
          return isHidden === false && productLine.dataId === productLineId
        }
        return isHidden === false
      },
      D: () => {
        if (vendorId && groupBy === 'V') {
          return (
            isHidden === false &&
            isDueToBuy === true &&
            numberOfPurchases >= hits &&
            dataId === vendorId
          )
        }
        if (productLineId && groupBy === 'P') {
          return (
            isHidden === false &&
            isDueToBuy === true &&
            numberOfPurchases >= hits &&
            productLine.dataId === productLineId
          )
        }
        return (
          isHidden === false && isDueToBuy === true && numberOfPurchases >= hits
        )
      },
      Q: () => {
        if (vendorId && groupBy === 'V') {
          return isHidden === false && isQuoted === true && dataId === vendorId
        }
        if (productLineId && groupBy === 'P') {
          return (
            isHidden === false &&
            isQuoted === true &&
            productLine.dataId === productLineId
          )
        }
        return isHidden === false && isQuoted === true
      },
      I: () => {
        if (vendorId && groupBy === 'V') {
          return isHidden === false && isHistory === true && dataId === vendorId
        }
        if (productLineId && groupBy === 'P') {
          return (
            isHidden === false &&
            isHistory === true &&
            productLine.dataId === productLineId
          )
        }
        return isHidden === false && isHistory === true
      },
      H: () => {
        if (vendorId && groupBy === 'V') {
          return isHidden === true && dataId === vendorId
        }
        if (productLineId && groupBy === 'P') {
          return isHidden === true && productLine.dataId === productLineId
        }
        return isHidden === true
      },
      default: () => true
    }
    return filterParams[filterBy]()
  }

  sortBy = event => {
    this.setState({ sortBy: event.target.value }, () => {
      if (this.inquiryResultsGrid) {
        const gridApi =
          this?.inquiryResultsGrid?.connectedGrid?.current?.api || null

        if (gridApi) {
          setTimeout(() => {
            gridApi.refreshInMemoryRowModel('group')
          }, 0)
        }
      }
    })
  }

  defaultGroupSortComparator = (nodeA, nodeB) => {
    const { groupBy } = this.props
    const keyA = nodeA.key.split(':')
    const keyB = nodeB.key.split(':')

    /* MISSING sortBy */
    const comparator = this.state.sortBy

    const comparisonOp = (a, b, node, selection, comp) => {
      if (comp === selection) {
        if (a[node] > b[node]) {
          return -1
        } else if (a[node] < b[node]) {
          return 1
        }
        return 0
      }
      if (a[node] < b[node]) {
        return -1
      } else if (a[node] > b[node]) {
        return 1
      }
      return 0
    }

    if (keyA.length && keyB.length) {
      if (groupBy === 'T') {
        return comparisonOp(keyA, keyB, 1, 0, comparator)
      }
      if (groupBy !== 'T') {
        if (comparator === 0 || comparator === 1) {
          return comparisonOp(keyA, keyB, 0, 1, comparator)
        } else if (comparator === 2 || comparator === 3) {
          return comparisonOp(keyA, keyB, 1, 3, comparator)
        }
      }
    }
    return 0
  }

  updateGridOnFilterChange = value => {
    this.externalFilterChanged(value)
  }

  externalFilterChanged = newValue => {
    const gridApi =
      this?.inquiryResultsGrid?.connectedGrid?.current?.api || null

    if (gridApi) {
      gridApi.onFilterChanged()
    }
  }

  getContextMenuItems = params => {
    if (!params?.node?.data) {
      return []
    }

    const { canAdjustPrice, dataId, uomId } = params?.node?.data
    const { form, isMobile } = this.props

    if (!form || isMobile || !dataId || !uomId) {
      return []
    }

    return canAdjustPrice
      ? [
          {
            name: 'Price Detail',
            action: () => {
              this.props.dispatch(
                showPriceDetail(form, {
                  dataId,
                  UOMId: uomId,
                  gridCallbackFn: this.getFilteredGridRows
                })
              )
            }
          },
          {
            name: 'Adjusted Price Change',
            action: () =>
              this.props.dispatch(
                showAdjustedPrice(form, {
                  dataId,
                  UOMId: uomId,
                  gridCallbackFn: this.getFilteredGridRows
                })
              )
          },
          {
            name: 'Customer Stock Minimums',
            action: () => {
              this.props.dispatch(
                readCustomerStockMinimums.try(form, {
                  dataId,
                  uomId
                })
              )
            }
          }
        ]
      : [
          {
            name: 'Price Detail',
            action: () =>
              this.props.dispatch(
                showPriceDetail(form, {
                  dataId,
                  UOMId: uomId,
                  gridCallbackFn: this.getFilteredGridRows
                })
              )
          },
          {
            name: 'Customer Stock Minimums',
            action: () => {
              this.props.dispatch(
                readCustomerStockMinimums.try(form, {
                  dataId,
                  uomId
                })
              )
            }
          }
        ]
  }

  rowDoubleClicked = params => {
    const { form, isMobile } = this.props
    if (params.node.data && !isMobile) {
      const { dataId, uomId } = params.node.data
      this.props.dispatch(
        showPriceDetail(form, {
          dataId,
          UOMId: uomId,
          gridCallbackFn: this.getFilteredGridRows
        })
      )
    }
  }

  getFilteredGridRows = () => {
    const gridApi =
      this?.inquiryResultsGrid?.connectedGrid?.current?.api || null

    if (gridApi) {
      const rows = []
      gridApi.forEachNodeAfterFilter((node, index) => {
        if (node.data) {
          rows.push(node.data)
        }
      })
      return rows
    }
    return []
  }

  onCellValueChanged = params => {
    const { form } = this.props
    const {
      data: { recordId }
    } = params

    if (
      params.newValue === params.oldValue ||
      (params.oldValue === null && params.newValue === '')
    ) {
      return
    }

    if (params.colDef.field === 'quantity' && _isMounted) {
      this.props.dispatch(
        updateLineItemQuantity(form, {
          recordId,
          quantity: params?.newValue ? parseNumber(params.newValue) : '',
          showErrorModal: false
        })
      )
    }
  }

  getColumnDefs = memoize((colDefs = []) => {
    const { isMobile } = this.props

    if (Array.isArray(colDefs)) {
      if (isMobile) {
        return colDefs.map((col, i) => {
          col = {
            ...col
          }

          if (col.field === 'dataId') {
            col = {
              ...col,
              cellRendererParams: {
                gridCallbackFn: this.getFilteredGridRows
              }
            }
          }

          if (col.field === 'currentPrice') {
            col = {
              ...col,
              cellRendererFramework: CurrentPrice
            }
          }
          return col
        })
      } else {
        return colDefs.map((col, i) => {
          col = {
            ...col
          }

          if (col.field === 'currentPrice') {
            col = {
              ...col,
              cellRendererFramework: CurrentPriceTooltip
            }
          }
          return col
        })
      }
    }

    return colDefs
  }, plainDeepEqual)

  render() {
    // console.log('INQUIRY_RESULTS', this.props)

    const {
      columnDefsList,
      isMobile,
      isModal,
      // rowData,
      rowDataList,
      filterInterfaceDimensions,
      rowHeight
    } = this.props

    const rowData = rowDataList?.toJS ? rowDataList.toJS() : []
    const columnDefs =
      columnDefsList && columnDefsList.toJS ? columnDefsList.toJS() : []

    const offsetHeightCal = isMobile ? 70 : rowData.length ? 205 : 165
    let gridHeight =
      getClientHeight() - offsetHeightCal - filterInterfaceDimensions.height

    gridHeight = isMobile && gridHeight < 440 ? 440 : gridHeight

    const modalGridHeight =
      filterInterfaceDimensions?.top && filterInterfaceDimensions?.height
        ? getClientHeight() -
          filterInterfaceDimensions.top -
          filterInterfaceDimensions.height -
          115
        : gridHeight

    return (
      <Fragment>
        {!isMobile ? this.generateSortOptions() : null}
        <div
          className="order-pad-grid-wrapper clear"
          style={{
            margin: '0 0 10px 0',
            height: isModal ? modalGridHeight : gridHeight
          }}
        >
          <GridField
            columnDefs={this.getColumnDefs(columnDefs)}
            enableFilter
            enableSorting
            propertyName="inquiryResults"
            height={isModal ? modalGridHeight : gridHeight}
            getRowNodeId={getRowNodeId}
            ref={el => (this.inquiryResultsGrid = el)}
            doesExternalFilterPass={this.doesExternalFilterPass}
            isExternalFilterPresent={() => true}
            getContextMenuItems={this.getContextMenuItems}
            groupUseEntireRow
            groupDefaultExpanded={1}
            defaultGroupSortComparator={this.defaultGroupSortComparator}
            onCellValueChanged={this.onCellValueChanged}
            onRowDoubleClicked={this.rowDoubleClicked}
            components={{ numericCellEditor: NumericCellEditor }}
            rowHeight={rowHeight}
            singleClickEdit
            reactUi={false}
          />
        </div>
      </Fragment>
    )
  }
}

export default withContext({
  columnDefsList: form =>
    getIn(form, 'fields.inquiryResults.columnDefs') || emptyList,
  cutOffDate: form => getField(form, 'cutOffDate', ''),
  defaultGroupBy: form => getMeta(form, 'defaultGroupBy', 'V'),
  filterBy: form => getField(form, 'filterBy', 'A'),
  groupBy: form => getField(form, 'groupBy', ''),
  vendorId: form => getField(form, 'vendor', ''),
  productLineId: form => getField(form, 'productLine', ''),
  hits: form => getField(form, 'hits', ''),
  rowDataList: form =>
    getIn(form, 'fields.inquiryResults.rowData') || emptyList,
  gridEdited: form => form.get('gridEdited') || false
})(InquiryResults)
