/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0, jsx-a11y/no-static-element-interactions: 0, jsx-a11y/click-events-have-key-events: 0 */
import React from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import IndexSearch from 'components/Search/IndexSearch'
import { styles, validateCustomer } from 'pages/CustomerOrderPad/utils'
import { empty, getField, getMeta, getIn } from 'utils'

let shipToField = null
let customerInput = null

const labelStyle = {
  background: 'rgb(234, 238, 240)',
  borderRadius: '4px',
  color: 'rgb(81, 123, 156)',
  display: 'inline-block',
  fontSize: '12px',
  lineHeight: '16px',
  maxWidth: '100%',
  overflow: 'hidden',
  padding: '5px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'auto'
}

const SearchFields = ({
  customer,
  form,
  gridEdited,
  shipToMetaMap,
  shipToDescription,
  customerDescription
}) => {
  const shipToMeta =
    shipToMetaMap && shipToMetaMap.toJS ? shipToMetaMap.toJS() : {}
  const dispatch = useDispatch()

  // useEffect(() => {
  //   if (customer && shipToField && shipToField.focus) {
  //     shipToField.focus()
  //   }
  // }, [customer])

  const openCustomerMaster = () => {
    if (customer) {
      dispatch(
        openScreenAction({
          dataId: customer,
          image: 'accounting_accounts_receivable_customer_ledger_16.png',
          route: 'customermaster',
          activeKey: 'ledger',
          title: 'Customer Ledger',
          groupNames: ['invoices', 'summary']
        })
      )
    }
  }

  const getCustomContextMenuItems = () => {
    return customer
      ? [
          {
            text: 'Open Customer Ledger',
            enabled: true,
            disable: true,
            onClick: openCustomerMaster
          }
        ]
      : []
  }

  return (
    <DDIExpansionPanel title="Input">
      <div style={styles.flexLabels.wrapper}>
        <label htmlFor="customer" style={styles.flexLabels.label}>
          Customer:
        </label>
        <div
          style={{
            ...styles.flexLabels.input,
            marginRight: 10
          }}
        >
          <DDIIndexSearch
            propertyName="customer"
            metaKey="Customer"
            minWidth="100%"
            searchType="customer"
            indexSearchType="customer"
            id="customer-search-field"
            customContextMenuItems={getCustomContextMenuItems}
            displayDescription
            descriptionKey="customer Name"
            validate={[validateCustomer]}
            inputRef={el => (customerInput = el)}
            preventAutoDisable
          />
        </div>
      </div>
      <div style={styles.flexLabels.wrapper}>
        <label htmlFor="shipTo" style={styles.flexLabels.label}>
          Ship To:
        </label>
        <div
          style={{
            ...styles.flexLabels.input,
            marginRight: 10
          }}
        >
          <DDIIndexSearch
            propertyName="shipTo"
            meta={{
              ...shipToMeta,
              allowSearchAll: Boolean(customer)
            }}
            minWidth="100%"
            component={IndexSearch}
            placeholder="All Ship Tos"
            parentId={customer}
            searchType="shipTo"
            indexSearchType="shipTo"
            id="shipto-search-field"
            displayDescription
            descriptionKey="shipto Name"
            inputRef={el => (shipToField = el)}
          />
        </div>
      </div>
    </DDIExpansionPanel>
  )
}

export default withContext({
  customer: form => getField(form, 'customer', ''),
  gridEdited: form => form.get('gridEdited') || false,
  shipToMetaMap: form => getMeta(form, 'Ship To', empty),
  customerDescription: form => getIn(form, 'fields.customer.description') || '',
  shipToDescription: form => getIn(form, 'fields.shipTo.description') || ''
})(SearchFields)
